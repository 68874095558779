<template>
  <main>
    <SearchView :key="title" :apiUrl="apiUrl" searchQuery="" :showGenres="false" :title="title" :user="false"/>
  </main>
</template>

<script>
import SearchView from '@/components/product/SearchView'

export default {
  name: 'GenreView',
  components: {SearchView},
  data: function () {
    return {
      apiUrl: '/genre/' + this.$route.params.alias,
      title: ''
    };
  },

  mounted() {

    this.$nextTick(function () {
      this.setGenreTitle(this.$route);
    })

  },

  watch: {
    $route(to) {

      let alias = to.params.alias;

      if (to.name=== 'genre_page' &&  alias){
        this.apiUrl = '/genre/' + to.params.alias;
        this.setGenreTitle(to);
      }


    },
  },

  methods: {
    setGenreTitle($route) {
      console.log('setgenres');

      let alias = $route.params.alias;
      this.get('/genre-info', {alias: alias}).done((resp) => {
        this.title = resp.data.name;

        if (resp.data.title) {
          document.title = resp.data.title;
        }

        if (resp.data.footer_seo) {
          this.$store.commit('setFooter', resp.data.footer_seo);
        }

      });

    }
  },


  beforeRouteLeave(to, from, next) {

    if (to.name === 'home') {
      // this.$destroy();
    }

    next();
  }

}
</script>

<style scoped>
</style>
