<template>
  <main v-if="product">

    <section class="product-player" :style="background">
      <div class="product-head content-width">
        <div class="wrap">
          <div class="item-music">

            <ProductViewInfo :product="product" :userCorrect="userCorrect" :key="product.id"/>

          </div>

        </div>
        <div class="specifications">
          <div class="left">
            <h5>Specifications</h5>
            <div v-html="product.specification"></div>
          </div>
          <div class="right" v-html="product.description"></div>
        </div>
      </div>
      <div class="comments-wrap content-width">
        <div class="comments">
          <h2>Comments</h2>
          <div class="wrap-plugin">
            <div id="commento"></div>
          </div>
        </div>
      </div>

      <div class="similar-tracks-wrap content-width" v-if="similarProducts.length > 0">
        <div class="similar-tracks">
          <h2>Similar tracks</h2>
          <div class="content">
            <div class="item " v-for="(similarProduct) in similarProducts" :key="similarProduct.id">
              <router-link :to="{ name: 'product', params: { id: similarProduct.id, alias: similarProduct.alias }}">
                <img :src="similarProduct.photo" alt="">
              </router-link>

              <div class="audiogallery sound-plugin mode-normal menu-position-bottom menu-opened dzsag-inited transition-fade dzsag-loaded" style="opacity:1; ">
                <div class="items"></div>
                <ItemMusicContentHtml :item="similarProduct"/>
              </div>


            </div>
          </div>
        </div>
      </div>

    </section>

  </main>
</template>

<script>
import ItemMusicContentHtml from "./ItemMusicContentHtml";
import ProductViewInfo from "./ProductViewInfo";

export default {
  name: "ProductView",
  components: {ProductViewInfo, ItemMusicContentHtml},
  data: function () {
    return {
      product: false,
      background: '',
      similarProducts: []
    };
  },

  beforeRouteEnter(to, from, next) {

    var productId = to.params.id;
    console.log(productId);

    window.$.get(window.api_url + '/product/info/' + productId)
        .done((data) => {
          next((vm) => {
            vm.parseResponse(data);
            vm.initSimilar();
          });
        }).fail(function () {
      alert('Fail load product.');
    });
  },

  watch: {
    '$route': 'init'
  },

  computed: {
    userCorrect: function(){
      return this.product.user !== null;
    }
  },

  methods: {
    parseResponse(data) {
      if (data['status'] === 'ok') {
        this.product = data.data;

        if (typeof data.data['background'] !== 'undefined'
            && data.data.background !== null
            && data.data.background.trim().length > 0) {
          this.background = "background: url('" + encodeURI(data.data.background) + "')no-repeat center; background-size: cover;";
        } else {
          this.background = '';
        }

      }
      console.log(data);
    },

    init: function () {
      var productId = this.$route.params.id;
      console.log(productId);

      this.get('/product/info/' + productId)
          .done((data) => {
            this.parseResponse(data);
          })

      this.initSimilar();
    },

    initSimilar: function () {
      var productId = this.$route.params.id;
      console.log(productId);

      this.get('/product/similar/' + productId)
          .done((data) => {

            if (data['status'] === 'ok') {
              this.similarProducts = data.data;
            }

          });
    },

  }
}
</script>

<style scoped>

</style>
