<template>
  <ul :class="listClass">
    <li v-for="(item, key) in items" :key="key" :class="{'is-current': choosed === key  }"><a href="#" @click="choose"  :data-id="key">{{ item }}</a></li>
  </ul>
</template>

<script>

export default {
  name: "FilterRadiobox",
  props: ['items', 'listClass'],

  data: function () {
    return {
      choosed: null
    };
  },

  methods: {
    choose: function (e) {
      e.preventDefault();
      var id = e.target.getAttribute('data-id');

      if (this.choosed === id){
        this.choosed = null;
      } else {
        this.choosed = id;
      }

      this.$emit('changed', this.choosed);
    }
  }
}
</script>

<style scoped>

</style>