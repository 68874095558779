<template>
  <div id="create" class="popup-site popup-create" style="display: none;">
    <div class="main-wrap">
      <h2>Create Account</h2>
      <form :action="this.api_url + '/user/sign-up'"
            class="default-form" @submit="submitForm" method="post">

        <SimpleInput name="email" title="Email" :errors="errors">
          <input type="email" id="email" required="required" v-model="email"/>
        </SimpleInput>

        <SimpleInput name="password" title="Password" :errors="errors">
          <input type="password" id="password" required="required" v-model="password"/>
        </SimpleInput>

        <SimpleInput name="password_repeat" title="Confirm Password" :errors="errors">
          <input type="password" id="password_repeat" required="required" v-model="password_repeat"/>
        </SimpleInput>

        <div class="input-wrap-check is-error">
          <p class="checkbox">
            <label>
              <input type="checkbox" value="1" name="data" v-model="terms">
              <span></span> <b>I agree to Templeton’s <a href="#">Privacy Policy</a></b>
            </label>
          </p>
          <p v-show="termsShowMsg" style="color: #FF0000;">
            Required
          </p>
        </div>

        <div class="input-wrap-submit">
          <button type="submit" class="btn-default btn-big btn-black">Create account</button>
        </div>

        <div class="bottom-text">
          <p><a href="#" class="fancybox-forgot">Forgot password</a></p>
          <p>New to Templeton? <a href="#" class="fancybox-create"> Create an account</a></p>
        </div>

      </form>
    </div>
  </div>
</template>

<script>
import SimpleInput from "@/components/fields/SimpleInput";

export default {
  name: "SignUp",
  data: function () {
    return {
      email: '',
      password: '',
      password_repeat: '',

      terms: false,
      termsShowMsg: false,

      errors: {}
    };
  },
  components: {SimpleInput},
  methods: {
    submitForm: function (e) {
      e.preventDefault();

      if (!this.terms) {
        this.termsShowMsg = true;
        return;
      } else {
        this.termsShowMsg = false;
      }

      this.errors = {};


      this.post('/user/sign-up',
          {
            CreateUserForm: {
              email: this.email,
              password: this.password,
              password_repeat: this.password_repeat
            }
          }
      ).done( (data) => {

        if (data.status === 'ok') {
          this.showMsg(data.data.msg);
        } else if (data.status === 'fail') {

          var formName = 'createuserform-';

          var errors = {};

          this.$.each(data.errors, function (field, err) {
            var correctFieldName = field.replace(formName, '');

            errors[correctFieldName] = err;
          });

          this.errors = errors;

        }

      });

    }
  }

}
</script>

<style scoped>

</style>