<template>

  <section class="account">
    <div class="tabs content-width">
      <ProfileNav :id="id"/>

      <div class="account-content">

        <div class="select-block ">
          <label class="form-label" for="select-tab"></label>
          <select id="select-tab" class="select selectTab" @change="selectTab">
            <option value="1" :selected="activePage(1)">Profile</option>
            <option value="2" :selected="activePage(2)">My Products</option>
            <option value="3" :selected="activePage(3)">My Downloads</option>
            <option value="4" :selected="activePage(4)">My Likes</option>
            <option value="5" :selected="activePage(5)">Contact Us</option>
            <option value="6">Log out</option>
          </select>
        </div>

        <div class="tab-content">
          <slot></slot>
        </div>

      </div>

    </div>
  </section>

</template>

<script>

import ProfileNav from "@/components/user/ProfileNav";

export default {
  name: "Account",
  props: ['id'],
  components: {
    ProfileNav,
  },
  created() {
    console.log('account created');
    this.$nextTick(function () {
      this.$('.select').niceSelect();
    })
    var $ = this.$;

    var app = this;

    this.$(document).on('change', '#select-tab', function () {
      var pageId = parseInt($(this).val());
      console.log(';av', pageId);


      var routes = {
        1: '/profile',
        2: '/my-products',
        3: '/my-downloads',
        4: '/my-likes',
        5: '/my-likes',
        6: '/logout'
      };

      if (typeof routes[pageId] !== 'undefined') {
        var path = routes[pageId];

        if (path === '/logout') {
          location.href = path;
        } else {
          app.$router.push({path: path}).catch(function(){
            console.log('pass');
          });
          app.$('.select').niceSelect('update');
          console.log('routes[pageId]', path);
        }

      }

    });
  },
  methods: {
    activePage(id){
      return parseInt(id) === parseInt(this.id);
    },

    selectTab: function (e) {
      console.log(e);
      var app = this;

      //this.$('.selectTab li').on('click', function (e) {
      var pageId = e.target.getAttribute('data-value');

      console.log('pageId', pageId);

      var routes = {
        1: '/profile',
        2: '/my-products',
        3: '/my-downloads'
      };

      if (typeof routes[pageId] !== 'undefined') {
        app.$route.push({path: routes[pageId]});
        console.log('routes[pageId]', routes[pageId]);
      }

      //});

    }
  }
}
</script>

<style scoped>

</style>