<template>
  <main>
    <section class="top-sellers">
      <div class="content-width">
        <TopSellers/>
        <TrendsView/>
      </div>
    </section>
    <SearchView apiUrl="/search" :searchQuery="searchQuery" :showGenres="true" :title="title" :user="false"/>
  </main>
</template>

<script>
import SearchView from '@/components/product/SearchView'
import TopSellers from "@/components/product/TopSellers";
import TrendsView from "@/components/product/TrendsView";

export default {
  name: 'Home',

  components: {SearchView, TopSellers, TrendsView},

  data: function () {
    return {
      title: '',
      searchQuery: '',
    };
  },

  watch: {
    $route(to) {
      console.log('to.query', to.query);
      if (typeof this.$route.query.q !== 'undefined'){
        this.searchQuery = this.$route.query.q;
      }
    },

  },

  mounted() {

    this.$nextTick(function () {
      this.$('body').addClass('home');

      if (typeof this.$route.query.q !== 'undefined'){
        this.searchQuery = this.$route.query.q;
      }

      this.get('/home-seo').done((resp) => {

        if (resp.data.main_page_title) {
          document.title = resp.data.main_page_title;
        }

        if (resp.data.main_page_footer_seo) {
          this.$store.commit('setFooter', resp.data.main_page_footer_seo);
        }

      });

    })

  },

  beforeDestroy(){
    this.$('body').removeClass('home');
  },

  beforeRouteLeave(to, from, next) {

    if (to.name === 'genre_page') {
      // this.$destroy();

    }

    next();
  }

}
</script>

<style scoped>
</style>
