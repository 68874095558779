<template>
  <main>
    <SearchView :apiUrl="apiUrl" searchQuery="" :showGenres="true" :title="title" :user="user"/>
  </main>
</template>

<script>
import SearchView from '@/components/product/SearchView'

export default {
  name: 'UserPage',
  components: {SearchView},
  data: function () {
    return {
      apiUrl: '/user/' + this.$route.params.id,
      title: '',
      user: false
    };
  },

  created() {
    this.setUser(this.$route);
  },

  watch: {
    $route(to, from) {

      if (to.name === 'user_page' && to.name === from.name){
        this.apiUrl = '/user/' + to.params.id;
        this.setUser(to);
      }

    },
  },

  methods: {
    setUser($route) {
      console.log('set user', $route.params.id)
      let userId = $route.params.id;

      if (typeof userId !== 'undefined') {

        this.get('/user/info/' + userId).done((resp) => {
          console.log('resp', resp);

          if (resp.status === 'ok') {
            this.user = resp.data;
          } else if (resp.status === 'fail') {
            this.user = false;
            this.showMsg(resp.errors.msg)
            this.$router.back('/');
          }

        });

      }

    }
  }

}
</script>

<style scoped>
</style>
