<template>
  <div class="slider-main">
    <div class="slider-clipper" style="height: 53px;">
      <div
          :class="{ 'preload-method-metadata dzsap-inited skin-wave skin-wave-mode-normal skin-wave-wave-mode-line skin-wave-wave-mode-canvas-mode-normal scrubbar-type-wave audioplayer has-thumb structure-setuped media-setuped under-720 under-500 active active-from-gallery meta-loaded listeners-setuped time-total-visible under-240 under-400 transitioning-out-complete': true, 'is-playing': isPlaying}"
          style="width: 100%;" data-thumb="img/img-4-1.jpg" data-bgimage="img/bg.jpg"
          data-scrubbg="waves/scrubbg.png" data-scrubprog="waves/scrubprog.png" data-videotitle="Audio Video"
          data-type="normal" data-source="sounds/sound.mp3" data-sourceogg="sounds/adg3.ogg"
          data-playerid="soundssoundmp3">

        <div class="audioplayer-inner">
          <div class="the-thumb-con">
            <div class="the-thumb" :style="img"></div>
          </div>
          <div class="the-media">

          </div>
          <div class="ap-controls">
            <div class="scrubbar">
              <div class="scrub-bg"></div>
              <div class="scrub-buffer"></div>
              <div class="scrub-prog" style="width: 0px;"></div>
              <div class="scrubBox"></div>
              <div class="scrubBox-prog"></div>
              <div class="scrubBox-hover"></div>
              <div class="total-time">{{item.duration}}</div>
              <div class="curr-time" style="left: 0px;">00:00</div>
            </div>
            <div class="con-controls">
              <div class="the-bg"></div>
              <div class="con-playpause-con">
                <div :class="{'con-playpause': true, 'playing': isPlaying }">
                  <div class="playbtn player-but" v-show="!isPlaying">
                    <div class="the-icon-bg"></div>
                    <div class="dzsap-play-icon" @click="onPlay">
                      <svg class="svg-icon" version="1.1" id="Layer_2" xmlns="http://www.w3.org/2000/svg"
                           xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="11.161px" height="12.817px"
                           viewBox="0 0 11.161 12.817" enable-background="new 0 0 11.161 12.817" xml:space="preserve"> <path
                          fill="#D2D6DB"
                          d="M8.233,4.589c1.401,0.871,2.662,1.77,2.801,1.998c0.139,0.228-1.456,1.371-2.896,2.177l-4.408,2.465 c-1.44,0.805-2.835,1.474-3.101,1.484c-0.266,0.012-0.483-1.938-0.483-3.588V3.666c0-1.65,0.095-3.19,0.212-3.422 c0.116-0.232,1.875,0.613,3.276,1.484L8.233,4.589z"></path>  </svg>
                    </div>
                  </div>
                  <div class="pausebtn player-but" v-show="isPlaying">
                    <div class="the-icon-bg"></div>
                    <div class="pause-icon" @click="onPause">
                      <svg class="svg-icon" version="1.1" id="Layer_3" xmlns="http://www.w3.org/2000/svg"
                           xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="12px" height="13px"
                           viewBox="0 0 13.415 16.333" enable-background="new 0 0 13.415 16.333"
                           xml:space="preserve"> <path fill="#D2D6DB"
                                                       d="M4.868,14.59c0,0.549-0.591,0.997-1.322,0.997H2.2c-0.731,0-1.322-0.448-1.322-0.997V1.618 c0-0.55,0.592-0.997,1.322-0.997h1.346c0.731,0,1.322,0.447,1.322,0.997V14.59z"></path>
                        <path fill="#D2D6DB"
                              d="M12.118,14.59c0,0.549-0.593,0.997-1.324,0.997H9.448c-0.729,0-1.322-0.448-1.322-0.997V1.619 c0-0.55,0.593-0.997,1.322-0.997h1.346c0.731,0,1.324,0.447,1.324,0.997V14.59z"></path> </svg>
                    </div>
                  </div>
                  <svg class="loading-svg" width="30" height="30" viewBox="0 0 44 44"
                       xmlns="http://www.w3.org/2000/svg" stroke="#fff">
                    <g fill="none" fill-rule="evenodd" stroke-width="2">
                      <circle cx="22" cy="22" r="1">
                        <animate attributeName="r" begin="0s" dur="1.8s" values="1; 20" calcMode="spline"
                                 keyTimes="0; 1" keySplines="0.165, 0.84, 0.44, 1"
                                 repeatCount="indefinite"></animate>
                        <animate attributeName="stroke-opacity" begin="0s" dur="1.8s" values="1; 0"
                                 calcMode="spline" keyTimes="0; 1" keySplines="0.3, 0.61, 0.355, 1"
                                 repeatCount="indefinite"></animate>
                      </circle>
                      <circle cx="22" cy="22" r="1">
                        <animate attributeName="r" begin="-0.9s" dur="1.8s" values="1; 20" calcMode="spline"
                                 keyTimes="0; 1" keySplines="0.165, 0.84, 0.44, 1"
                                 repeatCount="indefinite"></animate>
                        <animate attributeName="stroke-opacity" begin="-0.9s" dur="1.8s" values="1; 0"
                                 calcMode="spline" keyTimes="0; 1" keySplines="0.3, 0.61, 0.355, 1"
                                 repeatCount="indefinite"></animate>
                      </circle>
                    </g>
                  </svg>
                </div>
              </div>
              <div class="ap-controls-right"></div>
            </div>
            <div class="meta-artist-con">
              <div class="meta-artist">
												<span class="wrap-info">
													<span class="the-name">
                            <router-link :to="{ name: 'product', params: { id: item.id, alias: item.alias }}">
                            {{ item.title }}
                            </router-link>
                          </span>
													<span class="the-artist">{{ item.category ? item.category.name : '' }}</span>
												</span>
                <span class="the-style">{{ listGenres }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ItemMusicContentHtml",
  props: ['item'],
  data: function () {
    return {
      playing: true,
    };
  },
  computed: {
    img() {
      return 'background-image:url(' + this.item.thumb + ')';
    },
    listGenres() {
      var items = [];
      this.$.each(this.item.sub_genres, function (idx, item) {
        items.push(item.name);
      });

      return items.join(', ');
    },
    isPlaying() {
      return this.playing && this.$store.state.product && this.$store.state.product.id == this.item.id;
    }
  },
  methods: {
    onPlay(e) {
      clearInterval(window.footerInterval);
      window.footerInterval = 0;

      this.$(document).trigger('playBtnProgress');

      this.playing = true;

      if (typeof this.$store.state.product !== 'undefined' && this.$store.state.product.id !== this.item.id) {
        window.currentMedia = this.$(e.target);
        this.$store.commit('setFooterPlayer', this.item);
        console.log(this.$store.state.product);

        var player = document.getElementById("footer-player");

        console.log('player.api_get_time_curr() ', player.api_get_time_curr());

        if (player.api_get_time_curr() > 0) {
          this.setTrack(player);
        } else {
          this.setTrack(player);
        }

      } else {
        document.getElementById("footer-player").api_play_media();
      }

    },

    onPause() {
      this.playing = false;
      document.getElementById("footer-player").api_pause_media();
    },

    setTrack(player) {
      player.api_change_media(null,
          {
            type: "audio",
            fakeplayer_is_feeder: "off"
            , title: this.item.title
            , artist: this.item.category ? this.item.category.name : ''
            , source: this.item.preview_file
            , song_name: this.item.name
            , autoplay: "on"
            , thumb: this.item.thumb
          }
      );
    }

  }
}
</script>

<style scoped>

</style>
