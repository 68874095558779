import Vue from 'vue'
import Vuex from 'vuex'
import VueRouter from 'vue-router'

Vue.use(Vuex)
Vue.use(VueRouter)

import App from './App.vue'
import Home from './components/Home.vue'
import UserProduct from "@/components/product/UserProduct";
import ProductView from "@/components/product/ProductView";
import GenreView from "@/components/GenreView";
import Page404 from "@/components/Page404";
import MyProfile from "@/components/user/MyProfile";
import MyLikes from "@/components/user/MyLikes";
import MyProducts from "@/components/user/MyProducts";
import MyDownloads from "@/components/user/MyDownloads";
import UserPage from "@/components/UserPage";
import CartView from "@/components/CartView";
import SimplePage from "@/components/SimplePage";

const store = new Vuex.Store({
    state: {
        user: {},
        cartItemsCount: 0,
        cart: [],
        footer_seo: '',
        product: {
            title: '',
        },
        currentAudioOjb: false
    },
    mutations: {
        setUser(state, user) {
            state.user = user;
        },
        initCart(state) {
            var cartData = window.localStorage.getItem('cart');

            if (cartData !== null) {
                cartData = JSON.parse(cartData);

                if (typeof cartData!== 'undefined' && cartData.length > 0) {
                    console.log('cartData', cartData);

                    state.cart = cartData;
                    state.cartItemsCount = state.cart.length;
                }

            }
        },
        addItemToCart(state, item) {
            var jq = window.jQuery;

            var found = 0;
            var items = [];

            jq.each(state.cart, function (idx, el) {

                if (parseInt(el.product.id) === parseInt(item.product.id)) {
                    items.push(item);
                    found++;
                } else {
                    items.push(el);
                }

            });

            state.cart = items;

            if (found === 0) {
                state.cart.push(item);
            }

            state.cartItemsCount = state.cart.length;
            window.localStorage.setItem('cart', JSON.stringify(state.cart));
        },
        deleteItemFromCart(state, item) {
            var jq = window.jQuery;
            var items = [];

            jq.each(state.cart, function (idx, el) {
                if (parseInt(el.product.id) !== parseInt(item.product.id)) {
                    items.push(el);
                }
            });

            state.cart = items;
            state.cartItemsCount = state.cart.length;
            window.localStorage.setItem('cart', JSON.stringify(state.cart));
        },
        clearCart(state) {
            state.cart = [];
            state.cartItemsCount = 0;
            window.localStorage.removeItem('cart');
        },
        setFooter(state, content) {
            state.footer_seo = content;
        },
        setFooterPlayer(state, product) {
            state.product = product;
        },
    }
})

Vue.config.productionTip = false

window.api_url = '/api/v1';

if (location.href.match(/localhost/) !== null) {
    window.api_url = 'http://t.local/api/v1';
}

Vue.prototype.api_url = window.api_url;
Vue.prototype.$ = window.jQuery;

Vue.prototype.post = function (endpoint, data) {
    var current = this;

    if (typeof data === 'undefined') {
        data = {};
    }

    var token = document.querySelector('meta[name="csrf-token"]');

    if (token !== null && typeof token !== 'undefined') {
        data['_csrf'] = token.getAttribute('content');
    }

    return window.jQuery.post(window.api_url + endpoint, data).fail(function (a) {
        console.log(a.responseText);

        var data = JSON.parse(a.responseText);
        if (typeof data['msg'] !== 'undefined') {
            current.showMsg(data['msg']);
        }

    })
};

Vue.prototype.get = function (endpoint, data) {
    if (typeof data === 'undefined') {
        data = {};
    }

    var obj = endpoint;

    if (typeof endpoint['url'] !== 'undefined'){
        obj.url = window.api_url + obj.url;
    } else {
        obj = {
            url: window.api_url + endpoint,
            data: data
        };
    }

    var current = this;
    console.log(obj);

    return window.jQuery.get(obj).fail(function(a) {
        console.log(a.responseText);
        var data = JSON.parse(a.responseText);
        if (typeof data['msg'] !== 'undefined') {
            current.showMsg(data['msg']);
        }
    })
};

Vue.prototype.showMsg = function (msg) {
    window.jQuery('#notification-msg').text(msg);
    window.jQuery.fancybox.close();
    window.jQuery.fancybox.open(window.jQuery('#notification-modal'), {
        touch: false,
        autoFocus: false
    });
};

window.jQuery(document).on('currentProductPlay', function (event, product) {
    console.log('currentProductPlay', product);
    store.commit('setFooterPlayer', product);
});

const routes = [
    {
        path: '/',
        name: 'home',
        component: Home
    },
    {
        path: '/cart',
        component: CartView
    },
    {
        path: '/genre/:alias',
        name: 'genre_page',
        component: GenreView
    },
    {
        path: '/user/:id',
        name: 'user_page',
        component: UserPage
    },
    {
        path: '/add-product',
        component: UserProduct
    },
    {
        path: '/user-product/:id',
        component: UserProduct
    },
    {
        path: '/profile',
        component: MyProfile
    },
    {
        path: '/my-products',
        component: MyProducts
    },
    {
        path: '/my-downloads',
        component: MyDownloads
    },
    {
        path: '/my-likes',
        component: MyLikes,
    },
    {
        name: 'product',
        path: '/product/:id/:alias',
        component: ProductView
    },
    {
        path: '/about',
        component: SimplePage,
        props: { alias: 'about' }
    },
    {
        path: '/terms-and-conditions',
        component: SimplePage,
        props: { alias: 'terms-and-conditions' }
    },
    {
        path: '/privacy-policy',
        component: SimplePage,
        props: { alias: 'privacy-policy' }
    },
    {
        path: '*',
        component: Page404
    }

];

/*Router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresLogin) && ???) {
        // You can use store variable here to access globalError or commit mutation
        next("/Login")
    } else {
        next()
    }
});*/

const router = new VueRouter({
    mode: 'history',
    routes: routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        } else {
            return { x: 0, y: 0 }
        }
    }
});

router.beforeEach(function(to, from, next){
    window.$(document).trigger('clearAudioMemory');
    Vue.nextTick(function() {
        document.title = 'Templeton | templeton.store';
    });
    next();
});

var app = new Vue({
    render: h => h(App),
    store: store,
    router
});
app.$mount('#app');
