<template>

  <Account id="3">
    <Pagination title="My Downloads" action="/user/downloads" :showLikeBtn="true" :showDownload="true" :showCartBtn="false" :showDelBtn="false"/>
  </Account>

</template>

<script>

import Account from "@/components/user/Account";
import Pagination from "@/components/user/Pagination";

export default {
  name: "MyDownloads",
  components: {
    Account, Pagination
  }
}
</script>

<style scoped>

</style>