<template>

  <Account id="4">
    <Pagination title="My Likes" action="/user/likes" :showLikeBtn="true" :showDownload="true" />
  </Account>

</template>

<script>

import Account from "@/components/user/Account";
import Pagination from "@/components/user/Pagination";

export default {
  name: "MyLikes",
  components: {
    Account, Pagination
  }
}
</script>

<style scoped>

</style>