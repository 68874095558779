<template>
  <div id="login" class="popup-site popup-login" style="display: none;">
    <div class="main-wrap">
      <h2>Login</h2>
      <form :action="this.api_url + '/user/login'"
            class="default-form" @submit="submitForm" method="post">

        <SimpleInput name="email" title="Email" :errors="errors">
          <input type="email" id="email" required="required" v-model="email"/>
        </SimpleInput>

        <SimpleInput name="password" title="Password" :errors="errors">
          <input type="password" id="password" required="required" v-model="password"/>
        </SimpleInput>

        <div class="input-wrap-submit">
          <button type="submit" class="btn-default btn-big btn-black">Login</button>
        </div>
        <div class="bottom-text">
          <p><a href="#" class="fancybox-forgot" @click="openResetPassword">Forgot password</a></p>
          <p>New to Templeton? <a href="#" class="fancybox-create" @click="openSignUp"> Create an account</a></p>
        </div>
      </form>
    </div>
  </div>
</template>

<script>

import SimpleInput from "@/components/fields/SimpleInput";

export default {
  name: "Login",
  data: function () {
    return {
      email: '',
      password: '',

      errors: {},
    };
  },
  components: {SimpleInput},
  methods: {
    openSignUp: function () {
      this.$.fancybox.close();
      this.$.fancybox.open(this.$('#create'), {
        touch: false,
        autoFocus: false,
      });
    },

    openResetPassword: function () {
      this.$.fancybox.close();
      this.$.fancybox.open(this.$('#forgot-password'), {
        touch: false,
        autoFocus: false,
      });
    },

    submitForm: function (e) {
      e.preventDefault();

      this.errors = {};

      this.post('/user/login', {LoginForm: {email: this.email, password: this.password}}
      ).done( (data) => {

        if (data.status === 'ok') {
          this.$store.commit('setUser', data.data.user);
          this.$emit('onLoginDone');
          this.$.fancybox.close();
        } else if (data.status === 'fail') {

          var formName = 'loginform-';

          var errors = {};

          this.$.each(data.errors, function (field, err) {
            var correctFieldName = field.replace(formName, '');

            errors[correctFieldName] = err;
          });

          this.errors = errors;

        }

      });

    }
  }

}
</script>

<style scoped>

</style>