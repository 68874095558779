<template>
  <div :class="{'like': true, 'is-active': isLiked}">
    <a href="#" @click="onChange"><i class="fal fa-heart"></i></a>
  </div>
</template>

<script>
export default {
  name: "LikeBtn",
  props: ['product'],
  computed: {
    isLiked() {
      return this.product.liked === 1;
    }
  },
  methods: {
    onChange(e) {
      e.preventDefault();

      console.log('this.$store.state.user', this.$store.state.user);
      if (typeof this.$store.state.user['id'] !== 'undefined') {
        var status = this.product.liked ? 0 : 1;

        this.post('/product/like', {product_id: this.product.id, status: status})
            .done((resp) => {
              if (resp['status'] === 'ok') {
                this.product.liked = status;
              }
            });
      } else {
        this.$.fancybox.close();
        this.$.fancybox.open(this.$('#login'), {
          touch: false,
          autoFocus: false,
        });
      }

    }
  }
}
</script>

<style scoped>

</style>
