<template>
  <div class="download">
    <a href="#" @click="download"><img src="/img/download.svg" alt=""></a>
  </div>
</template>

<script>
export default {
  name: "DownloadBtn",
  props: ['product'],
  methods: {
    download(e) {
      e.preventDefault();

      if (this.$store.state.user.id) {

        this.get('/product/download/' + this.product.id).done( (data) => {
          if (data.status === 'ok') {
            if (data.data.msg) {
              this.showMsg(data.data.msg);
            }
            window.location.href = data.data.url;
          } else {
            this.showMsg(data.errors.msg);
          }
        });

      } else {
        this.showMsg('Need auth');
      }


    }
  }
}
</script>

<style scoped>

</style>