<template>
  <div id="notification-modal" class="popup-site popup-login" style="display: none;">
    <div class="main-wrap">
      <form action="#" class="default-form">
        <div class="bottom-text">
          <p id="notification-msg"></p>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
export default {
name: "Notification"
}
</script>

<style scoped>

</style>