<template>
  <div class="slider-wrap" v-if="products.length > 0">
    <h3>Trends</h3>
    <div class="swiper-container swiper-top">
      <div class="swiper-wrapper">
        <div class="swiper-slide" v-for="product in products" :key="product.id">
          <router-link :to="{ name: 'product', params: { id: product.id, alias: product.alias }}">
            <img :src="product.photo" alt="">
          </router-link>

          <div class="audiogallery sound-plugin mode-normal menu-position-bottom menu-opened dzsag-inited transition-fade dzsag-loaded" style="opacity:1; ">
            <div class="items"></div>
            <ItemMusicContentHtml :item="product"/>
          </div>

        </div>
      </div>
    </div>
    <div class="wrap-nav">
      <div class="swiper-button-next swiper-button-next-1"></div>
      <div class="swiper-button-prev swiper-button-prev-1"></div>
      <div class="swiper-pagination swiper-pagination-1"></div>
    </div>
  </div>
</template>

<script>

import ItemMusicContentHtml from "@/components/product/ItemMusicContentHtml";
export default {
  name: "TrendsView",
  components: {ItemMusicContentHtml},
  data: function () {
    return {
      products: [],
    };
  },

  mounted: function () {
    this.$nextTick(function () {
      this.init();
      console.log('this.initSound();');
    })
  },

  updated: function () {
    this.initTrendsSwiper();
    this.initSound();
  },

  methods: {

    init: function () {

      this.get('/product/trends')
          .done( (resp) => {

            if (resp['status'] === 'ok') {
              this.products = resp.data;
            }
          });
    },

    initTrendsSwiper: function () {
      this.$(document).trigger('initTrendsSwiper');
    },

    initSound: function () {
      // this.$(document).trigger('initPlayer');
    }

  }
}
</script>

<style scoped>

</style>