<template>
  <p v-show="hasErrors" class="error-msg">
    {{ errorMsgs }}
  </p>
</template>

<script>
export default {
  name: "ErrorMsg",
  props: ['name', 'errors'],
  data: function () {
    return {
      hasErrors: false,
      errorMsgs: '',
    };
  },

  watch: {
    errors: function (newVal) {

      if (typeof newVal !== 'undefined' && typeof newVal[this.name] !== 'undefined') {
        var errors = newVal[this.name];
        this.hasErrors = errors.length > 0;
        this.errorMsgs = errors.join('<br>');
        console.log('watch', this.errorMsgs);
      } else {
        this.hasErrors = false;
      }

    }
  }

}
</script>

<style scoped>

</style>