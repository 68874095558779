<template>
  <div class="item" :data-id="id" :data-title="title"><p>{{title}}</p><a href="#" class="delete-tags"><img src="/img/icon-12.svg" alt=""></a>
  </div>
</template>

<script>
export default {
name: "Tag",
  props: ['title', 'id']
}
</script>

<style scoped>

</style>