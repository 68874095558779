<template>
  <div class="left" v-if="products.length > 0">
    <h1>Top sellers</h1>
    <div class="wrap-music">
      <div class="item-music" v-for="product in products" :key="product.id">
        <div class="audiogallery sound-plugin">
          <ItemMusicContent :item="product" :showLikeBtn="true" :showDownload="true" :showCartBtn="!product.isBought"/>
          <ItemMusicContentHtml :item="product"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ItemMusicContent from "@/components/product/ItemMusicContent";
import ItemMusicContentHtml from "@/components/product/ItemMusicContentHtml";

export default {
  name: "TopSellers",
  components: {ItemMusicContent, ItemMusicContentHtml},

  data: function () {
    return {
      products: [],
    };
  },

  mounted: function () {
    this.$nextTick(function () {
      this.init();
    })
  },

  updated: function () {
    this.initSound();
  },

  methods: {

    init: function () {

      this.get('/product/top-sellers')
          .done( (resp) => {

            if (resp['status'] === 'ok') {
              this.products = resp.data;
            }
          });
    },

    initSound: function () {
      // this.$(document).trigger('initPlayer');
    }

  }
}
</script>

<style scoped>

</style>