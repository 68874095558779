<template>

  <ul :class="listClass">
    <li v-for="(item, key) in items" :key="key" :class="{'is-selected': isExist(key)  }"><a href="#" @click="choose"  :data-id="key">{{ item }}</a></li>
  </ul>


</template>

<script>

export default {
  name: "FilterCheckbox",
  props: ['items', 'listClass'],

  data: function () {
    return {
      choosed: []
    };
  },

  methods: {
    choose: function (e) {
      e.preventDefault();
      var id = e.target.getAttribute('data-id');

      var temp = this.choosed;

      if (this.isExist(id)){
        temp.splice(temp.indexOf(id), 1);
      } else {
        temp.push(id);
      }

      this.choosed = temp;

      this.$emit('changed', this.choosed);
    },
    isExist: function(key){
      return this.choosed.indexOf(key) > -1;
    }
  }
}
</script>

<style scoped>

</style>