<template>
  <section class="music-content music-content-deck" id="templates">
    <div class="content-width">
      <ProductFilter :apiUrl="apiUrl"
                     :showGenres="showGenres"
                     :query="q"
                     :sort="sort"
                     @onSearch="onSearch"
                     @loadingStatus="loadingStatus">
        <div class="item item-0" v-show="user">
          <figure>
            <img :src="user.photo" alt="">
          </figure>
          <p class="name">{{ user.name }}</p>
          <router-link to="/add-product" class="btn-default btn-black"
                       v-show="user && user.id === $store.state.user.id">+ Add Product
          </router-link>
        </div>
      </ProductFilter>
      <div class="filter-content ">
        <form class="top-sort" action="#" name="top-sort">
          <div class="form-search">
            <button type="submit" class="btn"><i class="far fa-search"></i></button>
            <label for="search-music"></label>
            <input type="search" id="search-music" v-model="q" @keypress.enter="enterQuery" class="input-search"
                   required="required" placeholder="Search by genre, albums, mood..."/>
            <a href="#" class="clear-search" @click="clearSearchField"><i class="fal fa-times"></i></a>
          </div>
          <FilterSort :value="sort" @changed="sortChanged"/>
          <div class="open-filter">
            <a href="#mob-filter"><img src="/img/icon-4.svg" alt="">Filters</a>
          </div>
          <div class="sort-item-wrap">
            <ul class="sort-item">
              <li :class="{'tile': true, 'is-active': isGrid}" @click="changeView(1)">
                <a href="#" @click.prevent>
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                </a>
              </li>
              <li :class="{'line': true, 'is-active': !isGrid}" @click="changeView(2)">
                <a href="#" @click.prevent>
                  <span></span>
                  <span></span>
                  <span></span>
                </a>
              </li>
            </ul>
          </div>
        </form>
        <div class="wrap-content">
          <h2 v-show="listTitle.length > 0">{{ listTitle }}</h2>
          <div :class="{'wrap-music wrap-music-big-item': true, 'is-grid': isGrid, 'is-tile': !isGrid }">
            <ItemMusic v-for="product in this.products" :key="product.id" :item="product" :showLikeBtn="true"
                       :showDownload="product.isBought" :showCartBtn="!product.isBought"/>
          </div>
          <div class="loading-msg" v-show="empty_results">Sorry we didn't find any results<span v-show="q.length > 0"> for "{{ q }}"</span>
          </div>
          <div class="loader-wrap" v-show="loading && products.length > 0">
            <div class="loader" >Loading...</div>
          </div>
          <div class="loadingContainer"></div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import ProductFilter from "@/components/product/ProductFilter";
import ItemMusic from "@/components/product/ItemMusic";
import FilterSort from "@/components/product/FilterSort";

export default {
  name: "SearchView",
  props: ['searchQuery', 'apiUrl', 'title', 'showGenres', 'user'],
  data: function () {
    return {
      q: '',
      sort: 1,
      products: [],
      loading: false,
      empty_results: false,
      tempTitle: '',

      tileViewType: 0,
      lineViewType: 0,

      isGrid: true,
    };
  },
  components: {
    ProductFilter,
    ItemMusic,
    FilterSort
  },

  computed: {
    listTitle: function () {
      if (this.tempTitle.trim().length > 0) {
        return this.tempTitle;
      } else {
        return this.title;
      }
    }
  },

  mounted() {
    this.$nextTick(function () {
      this.q = this.searchQuery;
      this.$(document).trigger('initFilterPage');
    })
    console.log('created search view')
  },

  watch: {
    searchQuery(to) {
      this.q = to;
    },
  },

  methods: {

    onSearch(items, isNextPage) {
      this.tempTitle = '';
      console.log('isNextPage', isNextPage);
      if (isNextPage) {
        this.products = this.products.concat(items);
      } else {
        this.products = items;
        if (items.length === 0) {
          this.empty_results = true;
        }
        this.tempTitle = this.q;
      }

      if (this.products.length > 0) {
        this.$nextTick(function () {
          //this.$store.commit('setFooterPlayer', this.products[0]);
          this.initSound();
        });
      }

      this.$(".music-content .filter-wrap .wrap").getNiceScroll().resize();

    },

    loadingStatus(status) {
      console.log('loading', status);
      this.loading = status;
      if (status) {
        this.empty_results = false;
      }
    },

    clearSearchField() {
      this.q = '';
    },

    enterQuery(e) {
      e.preventDefault();
      this.q = e.target.value;
      console.log('e.target.value', e.target.value);
    },

    sortChanged(id) {
      this.sort = id;
    },

    changeView: function (type) {

      if (type === 1) {
        if (this.tileViewType > 0) {
          this.$('body,html').animate({scrollTop: this.tileViewType});
        }

        this.lineViewType = this.$(window).scrollTop();
        this.isGrid = true;
      } else {
        if (this.lineViewType > 0) {
          this.$('body,html').animate({scrollTop: this.lineViewType});
        }

        this.tileViewType = this.$(window).scrollTop();
        this.isGrid = false;
      }

    },

    initSound: function () {
      //this.$(document).trigger('initHomePagePlayerInline');
    }

  }
}
</script>

<style scoped>

</style>
