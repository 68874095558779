<template>
  <div class="player-wrap" id="footer-player-wrap">
    <div class="content-width">

      <div class="audiogallery sound-plugin-volume" style="opacity:0; ">
        <div class="items">
          <div id="footer-player" class="audioplayer-tobe" style="width:100%; " data-thumb="/img/img-3.jpg"
               data-bgimage="img/bg.jpg" data-scrubbg="waves/scrubbg.png"
               data-scrubprog="waves/scrubprog.png" data-videoTitle="Audio Video"
               data-type="normal" data-source="fake" data-sourceogg="sounds/adg3.ogg">

            <div class="meta-artist">
              <span class="the-name">{{ $store.state.product.title }}</span>
              <span class="the-artist">Ableton Live</span>

            </div>

            <div class="pieProgress" role="progressbar">
              <div class="progress__meter"><span class="progress__label"></span></div>
            </div>

          </div>
          <div class="nav-item">

            <div class="dropdown-wrap">
              <a href="#"><i class="far fa-ellipsis-h"></i></a>
              <div class="dropdown-list">
                <ul>
                  <li><p>info-1</p></li>
                  <li><p>info-2</p></li>
                  <li><p>info-3</p></li>
                </ul>
              </div>
            </div>

            <LikeBtn :product="$store.state.product" v-show="$store.state.user"/>

            <DownloadBtn :product="$store.state.product" v-show="$store.state.user"/>

            <CartBtn :product="$store.state.product" v-show="$store.state.user"/>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DownloadBtn from "@/components/product/components/DownloadBtn";
import LikeBtn from "@/components/product/components/LikeBtn";
import CartBtn from "@/components/product/components/CartBtn";

export default {
  name: "FooterPlayer",
  components: {DownloadBtn, LikeBtn, CartBtn},

  mounted: function () {
    this.$nextTick(function () {
      this.$(document).trigger('initFooterPlayer');
    });
  },
  updated: function () {
    this.$nextTick(function () {

      this.$('.pieProgress').asPieProgress({
        namespace: 'pieProgress',
        min: 0,
        max: 100,
        barsize: '6',
        barcolor: '#7B55F8',
        trackcolor: '#EDF1F4',
        size: 100
      });

      var players = this.$('.player-wrap .audioplayer.skin-wave .ap-controls .con-playpause-con, .popup-player-track .audioplayer.skin-wave .ap-controls .con-playpause-con');

      this.$.each(players, (idx, player) => {
        var prevBtn = this.$(player).find('.prev-track');
        var nextBtn = this.$(player).find('.next-track');

        if (prevBtn.length === 0) {
          this.$(player).prepend("<div class='wrap'><a href=\"#\" class=\"prev-track\"><img src=\"/img/prev.svg\" alt=\"\"></a></div>");
        }

        if (nextBtn.length === 0) {
          this.$(player).append("<div class='wrap'><a href=\"#\" class=\"next-track\"><img src=\"/img/next.svg\" alt=\"\"></a></div>");
        }

      });

      // this.$(document).trigger('initFooterPlayer');
    })
  },

}
</script>

<style scoped>

</style>
