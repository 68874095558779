<template>
  <div class="content-item content-item-1">
    <h1>Profile</h1>
    <div class="wrap">
      <form :action="this.api_url + '/user/profile'"
            class="default-form" @submit="submitForm" method="post">

        <div class="input-wrap-file">
          <figure>
            <img :src="photo" alt="">
            <p id="name-file"></p>
          </figure>
          <label for="photo-user">
            <input type="file" name="photo-user" id="photo-user" @change="userPhoto" accept=".jpg, .jpeg, .png">
            <span class="btn-default">Upload new picture</span>
            <span v-show="uploadProgress.length > 0">{{ uploadProgress }}</span>
          </label>
        </div>

        <SimpleInput name="email" title="Email" :errors="errors" :value="email">
          <input type="email" id="email" required="required" v-model="email"/>
        </SimpleInput>

        <SimpleInput name="first_name" title="First Name" :errors="errors" :className="{'input-wrap-50': true}"
                     :value="first_name">
          <input type="text" id="first_name" v-model="first_name"/>
        </SimpleInput>

        <SimpleInput name="last_name" title="Last Name" :errors="errors" :className="{'input-wrap-50': true}"
                     :value="last_name">
          <input type="text" id="last_name" v-model="last_name"/>
        </SimpleInput>

        <SimpleInput name="password" title="Current Password" :errors="errors">
          <input type="password" id="password" v-model="password"/>
        </SimpleInput>

        <SimpleInput name="new_password" title="New Password" :errors="errors">
          <input type="password" id="new_password" v-model="new_password"/>
        </SimpleInput>

        <SimpleInput name="confirm_password" title="Confirm Password" :errors="errors">
          <input type="password" id="confirm_password" v-model="confirm_password"/>
        </SimpleInput>

        <div class="input-wrap-submit">
          <button type="submit" class="btn-default btn-big btn-black">Save profile</button>
        </div>

      </form>
    </div>
  </div>
</template>

<script>
import SimpleInput from "@/components/fields/SimpleInput";

export default {
  name: "Profile",
  props: ['user'],
  data: function () {
    return {
      photo: '',
      uploadProgress: 0,

      email: '',
      first_name: '',
      last_name: '',
      password: '',
      new_password: '',
      confirm_password: '',

      errors: {},
    };
  },
  components: {SimpleInput},

  created() {
    console.log('created A')
  },

  updated() {
    console.log('updated A')
  },

  mounted() {

    this.$nextTick(function () {
      this.photo = this.user.photo;
      this.email = this.user.email;
      this.first_name = this.user.first_name;
      this.last_name = this.user.last_name;
    })

  },

  watch: {
    user: function (user) {
      console.log(user)
      this.photo = this.user.photo;
      this.email = this.user.email;
      this.first_name = this.user.first_name;
      this.last_name = this.user.last_name;
    }
  },

  methods: {

    submitForm: function (e) {
      e.preventDefault();

      this.errors = {};

      this.post('/user/profile', {
            Profile: {
              email: this.email,
              first_name: this.first_name,
              last_name: this.last_name,
              password: this.password,
              new_password: this.new_password,
              confirm_password: this.confirm_password
            }
          }
      ).done( (data) => {

        if (data.status === 'ok') {
          this.$store.commit('setUser', data.data);
          this.password = this.new_password = this.confirm_password = '';
          this.showMsg('Profile Updated')
        } else if (data.status === 'fail') {

          var formName = 'profile-';

          var errors = {};

          this.$.each(data.errors, function (field, err) {
            var correctFieldName = field.replace(formName, '');

            errors[correctFieldName] = err;
          });

          this.errors = errors;

        }

      });

    },


    userPhoto: function (e) {
      e.preventDefault();

      var url = this.api_url + '/user/upload-avatar';

      var files = e.target.files;

      if (files.length > 0) {

        var userPhoto = files[0];
        console.log(url, userPhoto);

        var size = userPhoto.size / 1048576;

        if (size > 5) {
          this.showMsg('Image should be less than 5Mb');
          return;
        }

        var formData = new FormData();
        formData.append('media', files[0]);

        this.$.ajax({
          url: url,
          type: 'POST',
          data: formData,
          cache: false,
          dataType: 'json',
          processData: false,
          contentType: false,
          success:  (data) => {
            if (data.status === 'ok') {
              this.photo = data.url;
            } else {
              this.showMsg(data.msg);
            }
            this.uploadProgress = '';
          },
          error:  (jqXHR, textStatus) => {
            console.log(jqXHR);
            this.showMsg('ERRORS: ' + textStatus);
            this.uploadProgress = '';
          }

        });

      }


    }

  }

}
</script>

<style scoped>

</style>
