<template>
  <div class="audiogallery sound-plugin" style="opacity:0; ">
    <div class="items">
      <div class="audioplayer-tobe" style="width:100%; "
           ref="itemMusic" data-fakeplayer="#footer-player"
           :data-thumb="encodeURI(product.photo)" data-bgimage="/img/bg.jpg"
           data-scrubbg="waves/scrubbg.png"
           data-scrubprog="waves/scrubprog.png" data-videoTitle="Audio Video"
           data-type="normal"
           :data-source="product.preview_file">
        <div class="meta-artist">
										<span class="wrap-info">
											<span class="the-name">{{ product.title }}</span>
											<span class="the-artist">{{ product.category ? product.category.name : '' }}</span>
										</span>
          <span class="the-tags">
											<span class="tag" v-for="(tag) in product.tags" :key="tag.id" :value="tag.id">{{
                          tag.name
                        }}</span>
										</span>

        </div>

      </div>
      <div class="nav-item">

        <div class="price-wrap">
          <p class="label">From</p>
          <p class="price">${{ product.price }}</p>
        </div>

        <CartBtn :product="product" v-show="!product.isBought"/>
        <LikeBtn :product="product"/>

        <DownloadBtn :product="product" v-show="product.isBought"/>

        <div class="user-wrap" v-if="userCorrect">
          <router-link :to="`/user/` + product.user.id">
            <figure>
              <img :src="product.user.photo" alt="">
            </figure>
            <p>by <b>{{ product.user.name }}</b></p>
          </router-link>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import LikeBtn from "@/components/product/components/LikeBtn";
import CartBtn from "@/components/product/components/CartBtn";
import DownloadBtn from "@/components/product/components/DownloadBtn";

export default {
  name: "ProductViewInfo",
  props: ['product', 'userCorrect'],
  components: {DownloadBtn, LikeBtn, CartBtn},

  created() {

    this.$nextTick(function () {
      this.initSound();
      console.log('updated', this.$('.audioplayer-tobe').attr('class'))
    })

  },

  methods: {
    initSound: function () {
      this.$(document).trigger('initPlayer');
      this.$refs.itemMusic.product = this.product;
      //console.error('init ref');
      //console.log("trigger");
    }
  }
}
</script>

<style scoped>

</style>
