<template>
  <main>
    <section class="add-file">
      <img :style="{'vertical-align': 'sub'}" src="/img/loading.gif" height="50" v-show="loading">
      <div class="content-width" v-show="!loading">
        <h1>{{ formTitle }}</h1>
        <div class="content">
          <form :action="this.formAction"
                class="default-form" @submit="submitWithUpload" method="post">

            <div class="left">
              <p class="label">Track Artwork</p>
              <div class="input-wrap-title">
                <figure>
                  <img :src="currentPhoto" alt="">
                </figure>
                <div class="text-wrap">
                  <p>Minimum 500x500px size</p>
                  <label for="product-photo">
                    <input type="file" id="product-photo" accept=".jpg, .jpeg, .png">
                    <span class="btn-default btn-black">Upload Artwork</span>
                  </label>
                  <p id="file-Art"></p>
                </div>
              </div>
              <ErrorMsg name="photo" :errors="errors"/>
            </div>

            <div class="right">
              <p class="label">Audio files for download</p>
              <div class="input-wrap-file">
                <figure>
                  <img src="/img/icon-10.svg" alt="">
                </figure>
                <div class="text-wrap">
                  <div class="wrap">
                    <p class="title">MP3 file for player</p>
                    <p class="info"></p>
                    <p class="file"></p>
                  </div>
                  <label for="file-mp3-select">
                    <input type="file" id="file-mp3-select" accept=".mp3">
                    <span class="btn-default btn-black">Choose</span>
                  </label>
                  <div class="del-wrap">
                    <a href="#" class="btn-default btn-black">Delete</a>
                  </div>
                </div>
                <ErrorMsg name="preview_file" :errors="errors"/>
              </div>
              <div class="input-wrap-file">
                <figure>
                  <img src="/img/icon-11.svg" alt="">
                </figure>
                <div class="text-wrap">
                  <div class="wrap">
                    <p class="title">ZIP file for download</p>
                    <p class="info">No file selected</p>
                    <p class="file"></p>
                  </div>
                  <label for="file-select">
                    <input type="file" id="file-select" accept=".zip">
                    <span class="btn-default btn-black">Choose</span>
                  </label>
                  <div class="del-wrap">
                    <a href="#" class="btn-default btn-black">Delete</a>
                  </div>
                </div>
                <ErrorMsg name="zip_file" :errors="errors"/>
              </div>
            </div>

            <div class="bottom">
              <div class="wrap">
                <SimpleInput name="title" title="Title" :errors="errors" :value="title">
                  <input type="text" id="title" v-model="title"/>
                </SimpleInput>
                <p class="info">60 Maximum characters allowed</p>
              </div>

              <div class="wrap ">
                <div class="select-block">
                  <select id="select-category" class="select-plugin category-item" v-model="category_id">
                    <option value="0" hidden disabled selected></option>
                    <option v-for="(name, key) in this.categoriesList" :key="key" :value="key">{{ name }}</option>
                  </select>
                  <label :class="`label-info` + (category_id? ` is-selected`: ``)"
                         for="select-category">Category</label>
                  <ErrorMsg name="category_id" :errors="errors"/>
                </div>
              </div>


              <div class="wrap">
                <SimpleInput name="bpm" title="BPM" :errors="errors" :value="bpm">
                  <input type="number" id="bpm" v-model="bpm" :data_id="bpm"/>
                </SimpleInput>
              </div>

              <div class="wrap">
                <SimpleInput name="specification" title="Specification" :errors="errors" :value="specification">
                  <textarea id="specification" v-model="specification"></textarea>
                </SimpleInput>
                <p class="info">You can use this tool as music key finder, it gives you the Key in which a song was
                  composed. In music theory, the key of a piece is the group of pitches, or scale.</p>
              </div>

              <div class="wrap ">
                <div class="select-block">
                  <select id="select-genre" class="select-plugin primary-genre-item" v-model="primary_genre_id">
                    <option value="0" hidden disabled selected></option>
                    <option v-for="(name, key) in this.primaryGenres" :key="key" :value="key"
                            :selected="primary_genre_id === key">{{ name }}
                    </option>
                  </select>
                  <label :class="{'label-info': true, 'is-selected': primary_genre_id}" for="select-genre">Primary
                    genre</label>
                  <ErrorMsg name="primary_genre_id" :errors="errors"/>
                </div>
              </div>

              <div class="wrap">
                <div class="select-block select-block-wrap select-block-multi-wrap">
                  <select id="select-subgenre" multiple="multiple" class="multi-select-plugin" v-model="sub_genres">
                    <option v-for="(name, key) in this.subGenres" :key="key" :value="key"
                            :selected="isSubGenreSelected(key)">{{ name }}
                    </option>
                  </select>
                  <label :class="{'label-info': true, 'is-selected': sub_genres.length > 0}" for="select-subgenre">Subgenre</label>
                  <ErrorMsg name="sub_genres" :errors="errors"/>
                </div>

                <p class="info">You can start with artist name instead of song name.
                  6 subgenres maximum.</p>
              </div>

              <div class="wrap">
                <div class="tags-wrap">
                  <div class="label-tag">Tags</div>
                  <Tag :title="tag.name" :id="tag.id" v-for="tag in tags" :key="tag.id"/>
                  <div class="item-add"><a href="#" @click="openTagModal">Add a tag</a></div>
                </div>
                <p class="info">If the match is found then the music or song details will be displayed. 10 tags
                  maximum.</p>
              </div>

              <div class="wrap">
                <div class="input-wrap-radio">
                  <div :class="{'item-radio': true, 'is-selected': visible === 1}">
                    <label>
                      <input type="radio" value="1" v-model="visible" :checked="visible === 1">
                      <span></span> <b>Show product</b>
                    </label>
                  </div>
                  <div :class="{'item-radio': true, 'is-selected': visible === 0}">
                    <label>
                      <input type="radio" value="0" v-model="visible" :checked="visible === 0">
                      <span></span> <b>Hide product</b>
                    </label>
                  </div>
                </div>
                <p class="info"> You can show or hide your product as needed.</p>
              </div>

              <div class="input-wrap-submit">
                <button type="submit" class="btn-default btn-big btn-black" ref="submitButton">Publish a product
                </button>
                <img src="/img/loading.svg" ref="uploading" style="display: none"/>
                <p id="progress"></p>
              </div>

            </div>

          </form>
        </div>
      </div>
    </section>

    <TagModal @addNewTag="addNewTag"/>
  </main>
</template>

<script>
import SimpleInput from "@/components/fields/SimpleInput";
import ErrorMsg from "@/components/fields/ErrorMsg";
import Tag from "@/components/product/Tag";
import TagModal from "@/components/product/TagModal";

export default {
  name: "UserProduct",
  components: {SimpleInput, ErrorMsg, Tag, TagModal},
  data: function () {
    return {
      formTitle: 'Add Product',
      apiUrl: '/product/modify',
      loading: true,

      currentPhoto: '/img/img-10.jpg',

      origin_id: 0,

      title: '',
      category_id: 0,
      bpm: '',
      specification: '',
      primary_genre_id: 0,
      sub_genres: [],
      visible: 1,

      photo: false,
      preview: false,
      zip_archive: false,

      categoriesList: [],
      primaryGenres: {},
      subGenres: [],

      tags: [],

      errors: {},

      libsInit: false,

    };
  },

  watch: {
    $route(to) {
      this.apiUrl = '/product/modify/' + to.params.id;
    }
  },

  beforeMount() {
    this.get('/product/data').done((resp) => {
      this.init(resp);
    });

  },
  mounted() {

    this.$nextTick(function () {

      if (this.$route.params.id) {
        this.apiUrl = '/product/modify/' + this.$route.params.id;

        this.get('/product/info/' + this.$route.params.id).done((resp) => {

          this.formTitle = 'Edit Product';

          this.origin_id = resp.data.id;
          this.libsInit = false;
          this.currentPhoto = resp.data.photo;

          this.title = resp.data.title;
          this.category_id = resp.data.category.id;
          this.bpm = resp.data.bpm;
          this.specification = resp.data.specification;
          this.primary_genre_id = resp.data.primary_genre;

          let keys = Object.keys(resp.data.sub_genres);
          if (keys.length) {
            let ids = [];
            resp.data.sub_genres.forEach((item) => {
              ids.push(item.id)
            })
            this.sub_genres = ids;
          } else {
            this.sub_genres = [];
          }

          this.visible = resp.data.visible ? 1 : 0;
          this.tags = resp.data.tags;

          this.loading = false;
        });

      } else {
        this.loading = false;
      }

    })

  },


  updated() {
    if (this.libsInit === false) {
      this.libsInit = Object.keys(this.categoriesList).length > 0;
      this.initJSPlugins();
    }
  },

  computed: {
    formAction() {
      return this.api_url + this.apiUrl;
    }
  },

  methods: {
    init: function (resp) {
      var data = resp.data;
      this.categoriesList = data['categories'];
      this.primaryGenres = data['primary_genres'];
      this.subGenres = data['sub_genres'];
    },

    initJSPlugins() {
      this.$('.select-plugin').niceSelect('destroy');
      this.$('.select-plugin').niceSelect();
      this.$(".multi-select-plugin").bsMultiSelect('UpdateData');
    },

    openTagModal: function (e) {
      e.preventDefault();
      this.$.fancybox.open(this.$('#add-tag'), {
        touch: false,
        autoFocus: false,
      });
    },

    isSubGenreSelected(key) {
      return this.sub_genres.indexOf(parseInt(key)) > -1;
    },

    addNewTag: function (tagId, tagName) {
      var found = 0;

      this.$.each(this.tags, function (idx, item) {
        if (parseInt(item.id) === parseInt(tagId)) {
          found++;
        }
      });

      if (found === 0) {
        this.tags.push({id: tagId, name: tagName});
      }

      this.$.fancybox.close();
    },

    resetForm: function () {
      if (this.origin_id === 0) {
        this.title = '';
        this.category_id = 0;
        this.bpm = '';
        this.specification = '';
        this.primary_genre_id = '';
        this.sub_genres = [];
        this.visible = 1;
        this.tags = [];
        this.errors = {};
        this.initJSPlugins();
      }
    },

    progress(status) {
      if (status) {
        this.$(this.$refs.submitButton).hide();
        this.$(this.$refs.uploading).show();
      } else {
        this.$(this.$refs.submitButton).show();
        this.$(this.$refs.uploading).hide();
      }
    },

    submitWithUpload: function (e) {
      this.errors = {};
      this.primary_genre_id = this.$('.primary-genre-item li.selected').data('value');
      this.category_id = this.$('.category-item li.selected').data('value');

      var subGenres = this.$('#select-subgenre').val();

      console.log('subGenres', subGenres);

      if (subGenres !== null) {
        this.sub_genres = subGenres;
      }


      //IMPORTANT because js plugins not forks
      e.preventDefault();


      let productPhoto = this.$('#product-photo').get(0);
      if (productPhoto.files.length > 0) {
        this.photo = productPhoto.files[0];
      } else if (this.origin_id === 0) {
        this.showMsg('Product photo required');
        return false;
      }

      let productMp3 = this.$('#file-mp3-select').get(0);
      if (productMp3.files.length > 0) {
        this.preview = productMp3.files[0];
      } else if (this.origin_id === 0) {
        this.showMsg('Product mp3 file required');
        return false;
      }

      let productFile = this.$('#file-select').get(0);
      if (productFile.files.length > 0) {
        this.zip_archive = productFile.files[0];
      } else if (this.origin_id === 0) {
        this.showMsg('Product zip file required');
        return false;
      }

      let $ = this.$;

      let formData = new FormData();

      if (this.origin_id) {
        formData.set('ProductForm[origin_id]', this.origin_id);
      }

      formData.set('ProductForm[title]', this.title);
      formData.set('ProductForm[category_id]', this.category_id);
      formData.set('ProductForm[bpm]', this.bpm);
      formData.set('ProductForm[specification]', this.specification);
      formData.set('ProductForm[primary_genre_id]', this.primary_genre_id);

      $.each(this.sub_genres, function (idx, item) {
        formData.set('ProductForm[sub_genres][' + idx + ']', item);
      });

      $.each(this.tags, function (idx, item) {
        formData.set('ProductForm[tags][' + idx + ']', item.id);
      });

      if (this.photo) {
        formData.append('ProductForm[photo]', this.photo, this.photo.filename);
      }

      if (this.preview) {
        formData.append('ProductForm[preview_file]', this.preview, this.preview.filename);
      }

      if (this.zip_archive) {
        formData.append('ProductForm[zip_file]', this.zip_archive, this.preview.zip_archive);
      }

      this.progress(true);

      this.$.ajax({
        xhr: function () {
          var progressEl = $('#progress');
          var xhr = new window.XMLHttpRequest();
          xhr.upload.addEventListener("progress", function (evt) {
            if (evt.lengthComputable) {
              var percentComplete = (evt.loaded / evt.total) * 100;
              percentComplete = percentComplete.toFixed(0);
              var fileSizeMB = evt.loaded / 1048576;
              var sizeTxt = '[' + fileSizeMB.toFixed(2) + ' MB]';

              if (percentComplete < 100) {
                console.log(percentComplete + '% ' + sizeTxt);
                $(progressEl).text(percentComplete + '% ' + sizeTxt);
              } else {
                $(progressEl).text('');
              }
            }
          }, false);

          xhr.addEventListener("progress", function (evt) {
            if (evt.lengthComputable) {
              var percentComplete = (evt.loaded / evt.total) * 100;
              percentComplete = percentComplete.toFixed(0);
              var fileSizeMB = evt.loaded / 1048576;
              var sizeTxt = '[' + fileSizeMB.toFixed(2) + ' MB]';
              if (percentComplete < 100) {
                $(progressEl).text(percentComplete + '% ' + sizeTxt);
              } else {
                $(progressEl).text('');
              }

            }
          }, false);
          return xhr;
        },
        timeout: 1800000,
        async: false,
        url: this.formAction,
        type: 'POST',
        data: formData,
        cache: false,
        dataType: 'json',
        processData: false,
        contentType: false
      }).fail((a) => {
        console.log(a.responseText);

        var data = JSON.parse(a.responseText);
        if (typeof data['msg'] !== 'undefined') {
          this.showMsg(data['msg']);
        }
        if (typeof data['message'] !== 'undefined') {
          this.showMsg(data['message']);
        }
        this.progress(false);
      }).done((data) => {

        if (data.status === 'ok') {
          this.resetForm();
          this.showMsg(data.data.msg);
        } else if (data.status === 'fail') {

          var formName = 'productform-';

          var errors = {};
          var errCount = 0;

          this.$.each(data.errors, function (field, err) {
            var correctFieldName = field.replace(formName, '');
            errCount++;
            errors[correctFieldName] = err;
          });
        }

        this.errors = errors;

        if (errCount > 0) {
          console.log('scroll to erros');
          $('html, body').animate({
            scrollTop: $('.default-form').offset().top - 50
          }, 'slow');
        }
        this.progress(false);
      });

    }

  }
}
</script>

<style scoped>

</style>
