<template>

  <div class="content-item">
    <h2>{{ title }}</h2>
    <slot></slot>
    <ScrollView @onScrolledToEnd="search" :loading="loading">
      <div class="wrap-music wrap-music-big-item is-tile">
        <ItemMusic v-for="product in this.products" :key="product.id" :item="product"
                   :showLikeBtn="showLikeBtn"
                   :showDownload="showDownload"
                   :showCartBtn="isBought(product)" :showDelBtn="showDelBtn"/>
      </div>
      <div class="loading-msg" v-show="empty_results">List is empty</div>
    </ScrollView>
  </div>

</template>

<script>

import ScrollView from "@/components/user/ScrollView";
import ItemMusic from "@/components/product/ItemMusic";

export default {
  name: "Pagination",
  props: ['title', 'action', 'showLikeBtn', 'showDownload', 'showCartBtn', 'showDelBtn'],
  data: function () {
    return {
      products: [],
      min_id: 0,
      hasMore: true,
      loading: false,
      empty_results: false
    };
  },
  components: {
    ScrollView, ItemMusic
  },
  mounted() {

    this.$nextTick(function () {
      this.search();
    })

  },
  updated() {
    this.initSound();
    console.log('update search view')
  },
  methods: {

    search() {
      if (this.hasMore) {
        this.loading = true;
        this.empty_results = false;

        window.NProgress.start();

        this.get({
          url: this.action,
          data: {min_id: this.min_id},
          xhr: function () {
            var xhr = new window.XMLHttpRequest();
            xhr.upload.addEventListener("progress", function (evt) {
              if (evt.lengthComputable) {
                var percentComplete = evt.loaded / evt.total;
                console.log(percentComplete, 1);
                window.NProgress.set(percentComplete)
              }
            }, false);

            xhr.addEventListener("progress", function (evt) {
              if (evt.lengthComputable) {
                var percentComplete = evt.loaded / evt.total;
                console.log(percentComplete, 2);
                window.NProgress.set(percentComplete);
              }
            }, false);

            return xhr;
          },
        }).fail(() => {
          window.NProgress.done();
        })
            .done((data) => {

              if (this.products.length > 0) {
                this.products = this.products.concat(data.data.items);
              } else {
                this.products = data.data.items;
                if (this.products.length === 0) {
                  this.empty_results = true;
                }
              }

              this.min_id = data.data.min_id;
              this.loading = false;
              this.hasMore = data.data.has_more;
            }).done(() => {
          this.initSound();
        });
      }

    },

    initSound: function () {
      this.$(document).trigger('initHomePagePlayerInline');
      console.log("trigger");
    },

    isBought(product) {
      return !product.isBought && this.action !== '/user/products';
    }

  }

}
</script>

<style scoped>

</style>
