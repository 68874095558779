<template>
  <div class="close-wrap">
    <a href="#" class="del-item-music"><img src="/img/icon-9.svg" alt="" @click="remove"></a>
  </div>
</template>

<script>
export default {
  name: "DelBtn",
  props: ['product'],
  methods: {
    remove(e) {
      e.preventDefault();

      if (confirm('Delete ' + this.product.title + '?')) {

        this.post('/product/delete', {id: this.product.id}).done( (data) => {

          if (data.status === 'ok') {
            this.$emit('onDelete');
          } else {
            this.showMsg(data.errors.msg);
          }

        });
      }


    }
  }
}
</script>

<style scoped>

</style>