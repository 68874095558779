<template>
  <div :class="{'menu-responsive': true, 'user-logged': userLogged, 'unknown-user': !userLogged}">
    <div class="menu-wrap">
      <a href="#" class="close-menu"><i class="fal fa-times"></i></a>
      <div class="wrap">

        <div class="item">
          <ul class="mob-menu">
            <li class="search-wrap">
              <form action="#" name="form-search" class="form-search">
                <button type="submit" class="btn"><i class="far fa-search"></i></button>
                <label for="search"></label>
                <input type="search" id="search" v-model="q" class="input-search" required="required"
                       placeholder="Search by genre, albums..." @keypress.enter="enterQuery"/>
                <a href="#" class="clear-search" @click="clearQuery"><i class="fal fa-times"></i></a>
              </form>
            </li>

            <li class="item-menu scroll">
              <a href="#templates">Templates</a>
            </li>
            <li class="item-menu">
              <a href="#">Vocals</a>
            </li>
            <li class="item-menu">
              <a href="#">Drumkit</a>
            </li>
            <li class="item-menu">
              <a href="about.html">Presets</a>
            </li>

          </ul>
        </div>
        <div class="item">
          <ul class="list-account">
            <li class="unknown-item"><a href="#login" class="fancybox">Login</a></li>
            <li class="unknown-item"><a href="#create" class="fancybox">Create Account</a></li>
            <li class="default-item">
              <router-link to="/profile">Account</router-link>
            </li>
            <li class="default-item"><a href="#notifications" class="fancybox">Notifications <span>4</span></a>
            </li>
            <li class="default-item">
              <router-link to="/add-product">Add Product</router-link>
            </li>
            <li class="default-item"><a href="/user/logout">Log out</a></li>
          </ul>
        </div>
        <div class="item">
          <a href="contact.html" class="btn-default">Contact</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Menu",
  props: ['userLogged'],
  data: function () {
    return {
      q: '',
      timeout: 0
    };
  },

  methods: {

    go() {
      var isPush = true;

      if (typeof this.$route.query.q !== 'undefined') {
        var q = this.$route.query.q;

        if (q === this.q) {
          isPush = false;
        }
      }

      if (isPush) {
        this.$router.push({path: '/', query: {q: this.q}});
      } else {
        //this.$router.replace({path: '/', query: {q: this.q}});
      }


      var $ = this.$;

      var el = $('#search-music');

      if (el.length > 0) {
        this.$('html, body').animate({
          scrollTop: $(el).offset().top - 200
        }, 'slow');
      }

      this.q = '';
      this.$(".menu-responsive").removeClass('is-active');
    },

    enterQuery(e) {
      e.preventDefault();
      this.go();
    },

    clearQuery(e) {
      e.preventDefault();
      this.q = '';
    }
  }
}
</script>

<style scoped>

</style>