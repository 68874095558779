<template>
  <div v-html="content"></div>
</template>

<script>
export default {
  name: "SimplePage",
  props: ['alias'],
  data: function () {
    return {
      content: ''
    };
  },

  watch: {
    $route() {
      this.run();
    },
  },

  mounted() {
    this.run();
  },

  methods: {
    run(){
      this.get('/' + this.alias).done((data) => {
        if (data.status === 'ok') {
          document.title = data.data.title;
          this.content = data.data.content;
        } else {
          this.showMsg(data.errors.msg);
        }
      })
    }
  }
}
</script>

<style scoped>

</style>