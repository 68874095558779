<template>
  <div id="app">
    <FooterPlayer/>

    <Header :userLogged="userLogged"/>
    <Menu :userLogged="userLogged"/>
    <keep-alive include="Home,GenreView,UserPage" max="1">
      <router-view></router-view>
    </keep-alive>
    <Footer/>
    <Login @onLoginDone="onLoginDone"/>
    <RecoverPassword/>
    <SignUp/>
    <Notification/>
  </div>
</template>

<script>
import Header from '@/components/layout/Header.vue'
import Menu from '@/components/layout/Menu.vue'
import Footer from '@/components/layout/Footer.vue'
import Notification from '@/components/layout/Notification.vue'

import Login from '@/components/user/Login.vue'
import RecoverPassword from '@/components/user/RecoverPassword.vue'
import SignUp from '@/components/user/SignUp.vue'
import FooterPlayer from "@/components/layout/FooterPlayer";

export default {
  name: 'App',
  components: {
    Header,
    Menu,
    Footer,
    Login,
    RecoverPassword,
    SignUp,
    Notification,
    FooterPlayer
  },
  data: function () {
    return {
      userLogged: false,
      ts: 0
    };
  },
  mounted: function () {

    this.$nextTick(function () {

      this.get('/user/me').done((data) => {
        if (typeof data['data']['id'] !== 'undefined') {
          this.$store.commit('setUser', data.data);
          this.userLogged = true;
        }
      }).done(() => {
        console.log('init me', this.$store.state.user);
      });

      this.$store.commit('initCart');
    })

  },
  methods: {
    onLoginDone() {
      this.ts = Date.now();
      this.userLogged = true;
      console.log(this.ts);
    },
    signUpDone: function (user) {
      if (typeof user['id'] !== 'undefined') {
        this.user = user;
        this.userLogged = true;
        this.$.fancybox.close();
      }
    },
    setFooter() {
      console.log('set footer');
    }
  }
}
</script>

<style>

</style>
