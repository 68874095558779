<template>
  <div id="forgot-password" class="popup-site popup-forgot-password" style="display: none;">
    <div class="main-wrap">
      <h2>Reset password</h2>
      <p class="info">Please enter your email address. You will receive a link to create a new password via email.</p>
      <form :action="this.api_url + '/user/recover'"
            class="default-form" @submit="submitForm" method="post">

        <SimpleInput name="email" title="Email" :errors="errors">
          <input type="email" id="email" required="required" v-model="email"/>
        </SimpleInput>

        <div class="input-wrap-submit">
          <button type="submit" class="btn-default btn-big btn-black">Reset password</button>
        </div>

        <div class="bottom-text">
          <p><a href="#" class="fancybox-login" @click="openLogin">Back to Login</a></p>
          <p>New to Templeton? <a href="#" class="fancybox-create"> Create an account</a></p>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import SimpleInput from "@/components/fields/SimpleInput";

export default {
  name: "RecoverPassword",
  data: function () {
    return {
      email: '',

      errors: {},
    };
  },
  components: {SimpleInput},
  methods: {
    openSignUp: function () {
      this.$.fancybox.close();
      this.$.fancybox.open(this.$('#create'), {
        touch: false,
        autoFocus: false,
      });
    },

    openLogin: function () {
      this.$.fancybox.close();
      this.$.fancybox.open(this.$('#login'), {
        touch: false,
        autoFocus: false,
      });
    },

    submitForm: function (e) {
      e.preventDefault();

      this.errors = {};

      this.post('/user/recover', {RecoverPassPart1Form: {email: this.email}}
      ).done( (data) => {

        if (data.status === 'ok') {
          this.showMsg(data.data.msg);
        } else if (data.status === 'fail') {

          var formName = 'recoverpasspart1form-';

          var errors = {};

          this.$.each(data.errors, function (field, err) {
            var correctFieldName = field.replace(formName, '');

            errors[correctFieldName] = err;
          });

          this.errors = errors;

        }

      });

    }
  }

}
</script>

<style scoped>

</style>