<template>
  <section class="cart-review">
    <div class="content-width">
      <h1 v-show="$store.state.cartItemsCount > 0">Cart Review</h1>
      <h1 v-show="$store.state.cartItemsCount === 0">Cart Is Empty</h1>
      <div class="wrap" v-show="$store.state.cartItemsCount > 0">

        <div class="cart-wrap">
          <div class="item" v-for="cartItem in $store.state.cart" :key="cartItem.product.id">
            <figure>
              <a href="#">
                <img :src="cartItem.product.photo" alt="">
              </a>
              <div class="audiogallery sound-plugin disable-footer-player" style="opacity:0; ">
                <div class="items">
                  <div class="audioplayer-tobe" style="width:100%; "
                       :data-thumb="cartItem.product.photo" data-bgimage="/img/bg.jpg"
                       data-scrubbg="waves/scrubbg.png"
                       data-scrubprog="waves/scrubprog.png" data-videoTitle="Audio Video"
                       data-type="normal"
                       :data-source="cartItem.product.preview_file">
                  </div>
                </div>
              </div>
            </figure>
            <div class="text-wrap">
              <router-link :to="{ name: 'product', params: { id: cartItem.product.id, alias: cartItem.product.alias }}">
                <p class="title">{{ cartItem.product.title }}</p>
                <p>{{ cartItem.product.category.name }}</p>
              </router-link>
            </div>
            <div class="info-wrap">
              <div class="license-wrap">
                <p></p>
              </div>
              <div class="cost-wrap">
                <p>${{ cartItem.product.price }}</p>
              </div>
            </div>
            <a href="#" @click="removeItemFromCart(cartItem)" class="del-item"><img src="/img/icon-8.svg" alt=""></a>
          </div>
        </div>

        <div class="cost-total">
          <h2>Cart Summary</h2>
          <div class="content">
            <p>Subtotal <span>${{ total }}</span></p>
          </div>
          <div class="total">
            <p>Total <span>${{ total }}</span></p>
          </div>

          <div class="default-form" v-show="!$store.state.user.email">
            <SimpleInput name="email" title="Email" :errors="errors">
              <input type="email" v-model="email"/>
            </SimpleInput>
            <ErrorMsg name="products" :errors="errors"/>
          </div>

          <div class="btn-wrap">
            <a href="#" @click="submitCart"
               class="btn-black btn-default" :disabled="loading">Checkout <img :style="{'vertical-align': 'sub'}"
                                                                               src="/img/loading.gif" height="17"
                                                                               v-show="loading"></a>
          </div>

          <div class="btn-wrap" style="text-align: center;padding-top: 25px;">
            <img src="/img/PoweredbyStripe.png" atl="Powered by Stripe" width="150px">
          </div>

        </div>

      </div>
    </div>
  </section>
</template>

<script>
import SimpleInput from "@/components/fields/SimpleInput";
import ErrorMsg from "@/components/fields/ErrorMsg";

export default {
  name: "CartView",
  data: function () {
    return {
      email: '',
      loading: false,
      stripe: false,
      card: false,
      errors: {}
    };
  },
  components: {ErrorMsg, SimpleInput},

  created() {
    this.$nextTick(function () {
      this.$(document).trigger('initPlayer');
    })
  },


  updated() {
    this.$nextTick(function () {
      this.$(document).trigger('initPlayer');
    })
  },

  methods: {
    removeItemFromCart(item) {
      this.$store.commit('deleteItemFromCart', item);
    },

    submitCart(e) {
      e.preventDefault();

      var ids = [];

      this.$.each(this.$store.state.cart, function (idx, el) {
        ids.push({product_id: el.product.id, license: 1});
      });

      this.errors = {};
      this.loadingAction(true);

      this.post('/cart/submit', {products: ids, email: this.email}).done((data) => {
        if (data.status === 'ok') {
          // this.$store.commit('clearCart');
          location.href = data.data.sessionUrl;
        } else {
          this.loadingAction(false);
          var formName = 'cartform-';

          var errors = {};

          this.$.each(data.errors, function (field, err) {
            var correctFieldName = field.replace(formName, '');
            errors[correctFieldName] = err;
          });

          this.errors = errors;
        }
      });

    },

    loadingAction(isLoading) {
      this.loading = isLoading;
    },

  },
  computed: {
    total() {
      var total = 0;
      this.$.each(this.$store.state.cart, function (idx, el) {
        total += parseFloat(el.product.price);
      });
      return total;
    }
  }
}
</script>

<style scoped>

</style>
