<template>
  <Account id="1">
    <Profile :user="$store.state.user"/>
  </Account>
</template>

<script>
import Account from "@/components/user/Account";
import Profile from "@/components/user/Profile";

export default {
  name: "MyProfile",
  components: {
    Account, Profile
  }
}
</script>

<style scoped>

</style>