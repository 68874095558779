<template>
  <div :class="classList">
    <slot></slot>
    <label :for="name" :class="{'label-info': true, 'is-focus': fieldIsNotEmpty}">{{ title }}</label>
    <p v-show="hasErrors" v-html="errorMsgs" class="error-msg"></p>
  </div>
</template>

<script>
export default {
  name: "SimpleInput",
  props: ['value', 'name', 'title', 'className', 'errors'],
  data: function () {
    return {
      hasErrors: false,
      errorMsgs: '',
    };
  },

  computed: {
    classList: function () {
      var list = {'input-wrap': true, 'is-error': this.hasErrors};
      return Object.assign({}, list, this.className);
    },

    fieldIsNotEmpty(){
      return typeof this.value !== 'undefined' && (new String(this.value)).trim().length > 0;
    }
  },

  watch: {
    errors: function (newVal) {

      if (typeof newVal !== 'undefined' && typeof newVal[this.name] !== 'undefined') {
        var errors = newVal[this.name];
        this.hasErrors = errors.length > 0;
        this.errorMsgs = errors.join('<br>');
        console.log('watch', this.errorMsgs);
      } else {
        this.hasErrors = false;
      }

    }
  }

}
</script>

<style scoped>

</style>
