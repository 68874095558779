<template>
  <div class="items">

    <div ref="itemMusic" class="audioplayer-tobe" style="width:100%; " :data-thumb="item.thumb"
         data-fakeplayer="#footer-player"
         data-bgimage="/img/bg.jpg" data-videoTitle="Audio Video"
         data-type="normal" :data-source="item.preview_file">

      <div class="meta-artist">
												<span class="wrap-info">
													<span class="the-name">{{ item.title }}</span>
													<span class="the-artist">{{ item.category.name }}</span>
												</span>
        <span class="the-style">{{ listGenres }}</span>
      </div>

    </div>
    <div class="nav-item">

      <div class="dropdown-wrap">
        <a href="#"><i class="far fa-ellipsis-h"></i></a>
        <div class="dropdown-list">
          <ul>
            <li v-show="isUserProduct">
              <p>
                <router-link :to="`/user-product/` + item.id" class="'btn-default btn-color">Edit</router-link>
              </p>
            </li>
            <li><p>info-2</p></li>
            <li><p>info-3</p></li>
          </ul>
        </div>
      </div>

      <LikeBtn :product="item" v-show="showLikeBtn"/>

      <DownloadBtn :product="item" v-show="showDownload"/>
      <CartBtn :product="item" v-show="showCartBtn"/>
      <DelBtn :product="item" v-show="showDelBtn" @onDelete="onDelete"/>
    </div>

  </div>
</template>

<script>
import LikeBtn from "@/components/product/components/LikeBtn";
import CartBtn from "@/components/product/components/CartBtn";
import DelBtn from "@/components/product/components/DelBtn";
import DownloadBtn from "@/components/product/components/DownloadBtn";

export default {
  name: "ItemMusicContent",
  props: ['item', 'showLikeBtn', 'showDownload', 'showCartBtn', 'showDelBtn'],
  components: {DownloadBtn, LikeBtn, CartBtn, DelBtn},
  computed: {
    listGenres() {
      var items = [];
      this.$.each(this.item.sub_genres, function (idx, item) {
        items.push(item.name);
      });

      return items.join(', ');
    },
    isUserProduct() {
      return this.$route.path === '/my-products';
    }
  },
  mounted() {
    this.$nextTick(function () {
      this.$refs.itemMusic.product = this.item;
      console.error('init ref');
    })
  },
  methods: {
    onDelete() {
      this.$emit('onDelete')
    }
  }
}
</script>

<style scoped>

</style>