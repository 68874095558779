<template>

  <Account id="2">
    <Pagination title="My Products" action="/user/products" :showLikeBtn="false" :showDownload="false" :showCartBtn="false" :showDelBtn="true">
      <div class="btn-wrap">
        <router-link to="/add-product" class="btn-default btn-black">+ Add Product</router-link>
      </div>
    </Pagination>
  </Account>

</template>

<script>

import Account from "@/components/user/Account";
import Pagination from "@/components/user/Pagination";

export default {
  name: "MyProducts",
  components: {
    Account, Pagination
  }
}
</script>

<style scoped>

</style>