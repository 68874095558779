<template>
  <div>
    <slot></slot>
    <div class="loader" v-show="loading">Loading...</div>
    <div class="loadingContainer"></div>
  </div>
</template>

<script>
export default {
  name: "ScrollView",
  props: ['loading', 'onScrolledToEnd'],
  created() {
    this.$nextTick(function () {
      this.onScrollToEnd();
    })
  },
  methods: {
    onScrollToEnd() {
      var app = this;
      var $ = this.$;

      $(document).on('scroll', function () {
        var el = $('.loadingContainer');

        var containerLength = $(el)[0].offsetTop;

        var scroll = $(window).scrollTop();
        if (scroll >= (containerLength - 500) && !app.loading) {
          console.log('end');
          app.$emit('onScrolledToEnd');
        }
      });

    },
  },
  beforeDestroy(){
    this.$(document).off('scroll');
  }
}
</script>

<style scoped>

</style>
