<template>
  <div id="add-tag" class="popup-site popup-login" style="display: none;">
    <div class="main-wrap">
      <h2>Add a tag</h2>
      <form action="#" class="default-form" @submit="submit">
        <div class="input-wrap">
          <input type="text" id="tag" v-model="query" required="required"/>
          <label for="tag" class="label-info">Add a tag</label>
        </div>

        <div class="input-wrap-submit" v-for="tag in tags" :key="tag.id">
          <button type="button" class="btn-default btn-big btn-black" :data-id="tag.id" :data-name="tag.name" @click="submit">{{tag.name}}</button>
        </div>

        <div class="bottom-text">
          <p>If the match is found then the music or song details will be displayed. 10 tags maximum.</p>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  name: "TagModal",
  data: function () {
    return {
      query: '',
      tags: []
    };
  },
  watch: {
    query: function(val){
      this.get('/product/tags', {q: val}).done((data) => {
        this.tags = data.data;
      });
    },
  },
  methods: {
    submit: function(e){
      e.preventDefault();

      var id = e.target.getAttribute('data-id');
      var name = e.target.getAttribute('data-name');
      this.$emit('addNewTag', id, name);
    }
  }
}
</script>

<style scoped>

</style>