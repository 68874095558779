<template>
  <div class="filter-wrap">
    <div class="wrap">
      <slot></slot>
      <div class="item item-1" v-show="showGenres">
        <h3>Genre</h3>
        <FilterRadiobox :items="genresList" listClass="select-list" @changed="genresChanged"/>
      </div>
      <div class="item item-2">
        <h3>BPM</h3>
        <div class="input-range-wrap">
          <label for="from1"></label>
          <input type="text" value="0" class="inp js-from" id="from1"/>
          <label for="to1"></label>
          <input type="text" value="300" class="inp js-to" id="to1"/>
        </div>
        <label for="range-popup"></label>
        <input type="text" class="js-range-slider" value="" id="range-popup"/>
      </div>
      <div class="item item-3">
        <h3>Templates</h3>
        <FilterCheckbox :items="categoriesList" listClass="templates-list" @changed="categoriesChanged"/>
      </div>
      <div class="item item-4">
        <h3>Tags</h3>
        <FilterCheckbox :items="tagsList" listClass="templates-list" @changed="tagsChanged"/>
      </div>
      <div class="music-content music-content-mob" style="display: none;" id="mob-filter">
        <div class="content-width">

          <div class="filter-wrap">
            <div class="item item-1">
              <h3>Genre</h3>
              <FilterRadiobox :items="genresList" listClass="select-list" @changed="genresChanged"/>
            </div>
            <div class="item item-2">
              <h3>BPM</h3>
              <div class="input-range-wrap">
                <label for="from"></label>
                <input type="text" value="0" class="inp js-from" id="from"/>
                <label for="to"></label>
                <input type="text" value="300" class="inp js-to" id="to"/>
              </div>
              <label for="range-popup1"></label>
              <input type="text" class="js-range-slider" value="" id="range-popup1"/>
            </div>
            <div class="item item-3">
              <h3>Templates</h3>
              <FilterCheckbox :items="categoriesList" listClass="templates-list" @changed="categoriesChanged"/>
            </div>
            <div class="item item-4">
              <h3>Tags</h3>
              <FilterCheckbox :items="tagsList" listClass="templates-list" @changed="tagsChanged"/>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FilterRadiobox from "@/components/product/FilterRadiobox";
import FilterCheckbox from "@/components/product/FilterCheckbox";

export default {
  name: "ProductFilter",
  props: ['apiUrl', 'reload', 'showGenres', 'query', 'sort'],

  components: {FilterRadiobox, FilterCheckbox},

  data: function () {
    return {
      genresList: {},
      categoriesList: {},
      tagsList: {},

      genre: -1,
      categories: {},
      tags: {},
      bpm: '',

      page: 1,
      loading: false,

      queryTimeout: 0
    };
  },

  watch: {
    query: function () {
      console.log('query changed', this.query);
      clearTimeout(this.queryTimeout);

      this.loading = true;
      this.queryTimeout = setTimeout(() => {
        this.search();
      }, 1000);

    },
    sort: function () {
      this.search();
    },
    loading: function (val) {
      this.$emit('loadingStatus', val);
    },
    apiUrl: function () {
      this.search();
      console.log('reload');
    }
  },

  created() {
    this.init();

    this.$nextTick(function () {
      var $range = this.$(".js-range-slider").data("ionRangeSlider");
      if (typeof $range === 'undefined') {
        this.$(document).trigger('initRange');
      }
      this.$(".music-content .filter-wrap .wrap").niceScroll({
        cursorcolor: "#C2CBCF",
        horizrailenabled: false,
      });
    })

    this.search();
    console.log('created')
  },

  updated() {
    /*var $range = this.$(".js-range-slider").data("ionRangeSlider");
    if (typeof $range === 'undefined') {
      this.$(document).trigger('initRange');
    }
*/
    console.log('updated');
  },

  mounted() {
    this.registerEventOnChangeSlider();
  },

  beforeDestroy() {
    this.$(document).off('scroll');
    console.log('beforeDestroy');
  },

  methods: {

    init() {
      this.get('/filter-data').done((resp) => {
        this.genresList = resp.data.genres;
        this.categoriesList = resp.data.categories;
        this.tagsList = resp.data.tags;
      });
    },

    onScrollToEnd() {
      var app = this;
      var $ = this.$;

      $(document).scroll(function () {
        var el = $('.loadingContainer');

        if (el.length > 0) {
          var containerLength = $(el)[0].offsetTop;

          var scroll = $(window).scrollTop();
          if (scroll >= (containerLength - 500) && !app.loading) {
            app.searchWithPage();
          }
        }


      });

    },

    genresChanged(id) {
      this.genre = id;
      this.filterChanged();
      this.search();
    },

    categoriesChanged(items) {
      this.categories = items;
      this.filterChanged();
      this.search();
    },

    tagsChanged(items) {
      this.tags = items;
      this.filterChanged();
      this.search();
    },

    registerEventOnChangeSlider() {
      this.$(document).on('change_slider', (e, param) => {
        this.bpm = param;
        this.filterChanged();
        this.search();
      });
    },

    filterChanged() {
      window.scrollToStart = 1;

      if (window.innerWidth > 767) {
        this.$([document.documentElement, document.body]).animate({
          scrollTop: this.$('.filter-content .wrap-music').offset().top - 250
        }, 1);
      }
    },

    search(params) {
      var data = {
        genres: this.genre,
        bpm: this.bpm,
        templates: this.categories,
        tags: this.tags,

        q: this.query,
        sort: this.sort,
      };

      var addMore = false;
      if (typeof params !== 'undefined') {

        this.$.each(params, function (idx, param) {
          data[idx] = param;
        });

        addMore = typeof params['page'] !== 'undefined';
      }

      this.loading = true;
      window.NProgress.start();

      return this.get({
        url: this.apiUrl,
        data: data,
        xhr: function () {
          var xhr = new window.XMLHttpRequest();
          xhr.upload.addEventListener("progress", function (evt) {
            if (evt.lengthComputable) {
              var percentComplete = evt.loaded / evt.total;
              console.log(percentComplete, 1);
              window.NProgress.set(percentComplete)
            }
          }, false);

          xhr.addEventListener("progress", function (evt) {
            if (evt.lengthComputable) {
              var percentComplete = evt.loaded / evt.total;
              console.log(percentComplete, 2);
              window.NProgress.set(percentComplete);
            }
          }, false);

          return xhr;
        },
      }).fail(() => {
        window.NProgress.done();
      }).done((resp) => {
        if (resp.status === 'ok') {
          this.page = resp.data.nextPage;
          this.$emit('onSearch', resp.data.items, addMore);
        } else if (resp.status === 'fail') {
          try {
            this.showMsg(resp.errors.msg)
            this.$router.push({path: '/'});
          } catch (e) {
            console.log(e);
          }
        }
      }).done(() => {
        this.onScrollToEnd();
        this.loading = false;
        window.NProgress.done();
      });
    },

    searchWithPage() {

      if (this.page !== null) {
        this.search({page: this.page});
      } else {
        console.log('rich list');
      }

    }


  }
}
</script>

<style scoped>

</style>
