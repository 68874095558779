<template>
  <header :class="{'user-logged header-pages': userLogged, 'unknown-user': !userLogged}">
    <div class="top-line">
      <div class="content-width">
        <div class="left">
          <div class="logo">
            <router-link to="/">templeton</router-link>
          </div>
          <nav class="top-menu">
            <ul class="menu">
              <li :class="{'item-sub scroll': itemMenu.items.length > 0}" v-for="(itemMenu, key) in menu" :key="key">
                <a href="#" v-show="itemMenu.items.length > 0">{{ itemMenu.title }}</a>
                <ul class="sub-menu" v-show="itemMenu.items.length > 0">
                  <li v-for="(subItem, key2) in itemMenu.items" :key="key2">
                    <router-link :to="subItem.url">{{ subItem.title }}</router-link>
                  </li>
                </ul>
                <router-link v-show="itemMenu.items.length === 0" :to="itemMenu.url">{{ itemMenu.title }}</router-link>
              </li>
            </ul>
          </nav>
        </div>
        <div class="right">
          <div class="contact-wrap">
            <a href="#" class="btn-default">Contact Us</a>
          </div>
          <div class="login-wrap">
            <a href="#login" v-show="!userLogged" class="fancybox"><img src="/img/login.svg" alt=""></a>
            <a href="#" class=""><img src="/img/login.svg" alt=""></a>
            <ul class="list-login">
              <li>
                <router-link to="/profile">Profile</router-link>
              </li>
              <li>
                <router-link to="/my-products">My Products</router-link>
              </li>
              <li>
                <router-link to="/my-downloads">My Downloads</router-link>
              </li>
              <li>
                <router-link to="/my-likes">My Likes</router-link>
              </li>
              <li><a href="/user/logout">Log Out</a></li>
            </ul>
          </div>
          <div class="notifications-wrap">
            <a href="#" class=""><img src="/img/icon-3.svg" alt=""> <span>4</span></a>
            <div class="notifications-list-wrap">
              <h6>Notifications</h6>
              <ul class="notifications-list">

                <li>
                  <a href="#notifications" class="fancybox">
                    <b>New direct message</b>
                    <span>Your free artist account includes advanced stats to help you measure engagement, Your free artist account includes advanced stats to help you measure engagement</span>
                    <i>2 min ago</i>
                  </a>
                </li>

              </ul>
            </div>
          </div>
          <div v-show="userLogged" class="add-product-wrap">
            <router-link to="/add-product"><img src="/img/icon-1.svg" alt=""></router-link>
          </div>
          <div class="shopping-cart-wrap">
            <a href="#" @click="openCart"><img src="/img/cart-empty.svg" alt=""><span
                v-show="$store.state.cartItemsCount > 0">{{ $store.state.cartItemsCount }}</span></a>
          </div>
          <div class="menu-btn">
            <a href="#">
              <span></span>
              <span></span>
              <span></span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  name: "Header",
  props: ['userLogged'],

  data: function () {
    return {
      menu: [],
    };
  },

  mounted: function () {
    this.$nextTick(function () {


      this.get('/menu-data')
          .done( (resp) => {

            if (resp['status'] === 'ok') {
              this.menu = resp.data;
            }
          });

    })
  },

  methods: {
    openCart(e) {
      e.preventDefault();
      this.$router.push({path: '/cart'}).catch(function(e){
        console.log(e);
      });
    }
  }
}
</script>

<style scoped>

</style>