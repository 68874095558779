<template>
  <div class="shop-cart" v-show="!product.isBought">
    <a @click="addToCart" :class="{'btn-default btn-color fancybox': true, 'active': isActive}"><img
        src="/img/btn-cart.svg" alt=""></a>

    <div :id="popupId()" class="popup-site popup-added-product" style="display: none;">
      <div class="main-wrap">
        <h5>Product added to your cart</h5>
        <div class="product-wrap">
          <figure>
            <a href="#"><img :src="product.photo" alt=""></a>
          </figure>
          <div class="text-wrap">
            <a href="#">
              <h6 class="title">{{ product.title }}</h6>
              <p>{{ product.category ? product.category.name: '' }}</p>
              <p class="cost">${{ product.price }}</p>
            </a>
          </div>

        </div>
        <div class="btn-wrap">
          <a href="#" @click="openCart" class="btn-default btn-black">Checkout</a>
          <a href="#" class="btn-default " @click="close">Continue Shopping</a>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: "CartBtn",
  props: ['product'],
  data: function () {
    return {
      license: 1
    };
  },
  methods: {

    popupId() {
      return 'added-product' + this.product.id;
    },

    close(e) {
      e.preventDefault();
      this.$.fancybox.close();
    },

    openCart(e) {
      e.preventDefault();
      this.$('.fancybox-is-open').remove();
      this.$router.push({path: '/cart'}).catch(function (e) {
        console.log(e);
      });
    },

    addToCart(e) {
      e.preventDefault();
      if (this.isActive) {
        this.$store.commit('deleteItemFromCart', {
          product: {
            id: this.product.id
          }
        });
      } else {
        this.$store.commit('addItemToCart', {
          product: {
            id: this.product.id,
            alias: this.product.alias,
            title: this.product.title,
            category: this.product.category,
            photo: this.product.photo,
            preview_file: this.product.preview_file,
            price: this.product.price,
          }, license: this.license
        });

        this.$.fancybox.close();
        this.$.fancybox.open(this.$('#' + this.popupId()), {
          touch: false,
          autoFocus: false
        });

      }

    }
  },
  computed: {
    itemCartId() {
      return 'cart' + this.product.id;
    },
    isActive() {
      var found = 0;
      var product = this.product;

      this.$.each(this.$store.state.cart, function (idx, el) {
        if (parseInt(el.product.id) === parseInt(product.id)) {
          found++;
        }
      });

      return found > 0;
    }
  }
}
</script>

<style scoped>

</style>
