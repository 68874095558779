<template>
  <main>
    <section class="top-sellers">
      <div class="content-width">
        <div class="left">
          <h1>Page Not Found</h1>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
export default {
  name: "Page404"
}
</script>

<style scoped>

</style>