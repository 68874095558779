<template>
  <div class="nav-wrap">
    <div class="email-wrap">
      <p><img src="/img/login.svg" alt=""> {{ $store.state.user.email }}</p>
    </div>
    <ul class="tabs-menu">
      <li :class="isActive(1)"><router-link to="/profile"><p><span><img src="/img/user-1.svg" alt=""></span>Profile</p></router-link></li>
      <li :class="isActive(2)"><router-link to="/my-products"><p><span><img src="/img/user-2.svg" alt=""></span>My Products</p></router-link></li>
      <li :class="isActive(3)"><router-link to="/my-downloads"><p><span><img src="/img/user-3.svg" alt=""></span>My Downloads</p></router-link></li>
      <li :class="isActive(4)"><router-link to="/my-likes"><p><span><img src="/img/user-4.svg" alt=""></span>My Likes</p></router-link></li>
    </ul>
    <ul class="tabs-links">
      <li :class="isActive(5)"><router-link to="/profile"><p><span><img src="/img/user-5.svg" alt=""></span>Contact Us</p></router-link></li>
      <li><a href="/user/logout"><p><span><img src="/img/user-6.svg" alt=""></span>Log out</p></a></li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "ProfileNav",
  props: ['id'],
  methods: {
    isActive(id) {
      //console.log(parseInt(id) === parseInt(this.id), typeof id,this.id);
      return {'is-active': parseInt(id) === parseInt(this.id)};
    }
  }
}
</script>

<style scoped>

</style>