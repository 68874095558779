<template>
  <div class="item-music" v-show="show">
    <figure>
      <router-link :to="{ name: 'product', params: { id: item.id, alias: item.alias }}">
        <img :src="item.thumb" alt="">
      </router-link>
    </figure>
    <div class="text-wrap">
      <router-link :to="{ name: 'product', params: { id: item.id, alias: item.alias }}">
        <h6>{{ item.title }}</h6>
        <p>{{ item.category ? item.category.name : '' }}</p>
      </router-link>
    </div>
    <div
        class="audiogallery sound-plugin-line mode-normal menu-position-none menu-opened dzsag-inited transition-fade playlist-transition-fade dzsag-loaded"
        style="opacity: 1;" v-show="item.preview_file.length > 0">
      <div class="items">

        <div class="nav-item">

          <div class="dropdown-wrap">
            <a href="#"><i class="far fa-ellipsis-h"></i></a>
            <div class="dropdown-list">
              <ul>
                <li v-show="isUserProduct">
                  <p>
                    <router-link :to="`/user-product/` + item.id" class="'btn-default btn-color">Edit</router-link>
                  </p>
                </li>
                <li><p>info-2</p></li>
                <li><p>info-3</p></li>
              </ul>
            </div>
          </div>

          <LikeBtn :product="item" v-show="showLikeBtn"/>

          <DownloadBtn :product="item" v-show="showDownload"/>
          <CartBtn :product="item" v-show="showCartBtn"/>
          <DelBtn :product="item" v-show="showDelBtn" @onDelete="show = false"/>
        </div>

      </div>

      <ItemMusicContentHtml :item="item"/>

    </div>
  </div>

</template>

<script>
import LikeBtn from "@/components/product/components/LikeBtn";
import CartBtn from "@/components/product/components/CartBtn";
import DelBtn from "@/components/product/components/DelBtn";
import DownloadBtn from "@/components/product/components/DownloadBtn";
import ItemMusicContentHtml from "@/components/product/ItemMusicContentHtml";

export default {
  name: "ItemMusic",
  props: ['item', 'showLikeBtn', 'showDownload', 'showCartBtn', 'showDelBtn'],
  components: {ItemMusicContentHtml, DownloadBtn, LikeBtn, CartBtn, DelBtn},
  data: function () {
    return {
      show: true,
    };
  },
  computed: {
    isUserProduct() {
      return this.$route.path === '/my-products';
    },
  },
  methods: {


  }
}
</script>

<style scoped>

</style>