<template>
  <div class="sort-wrap">
    <div class="wrap">
      <a href="#"><span>Sort by</span> <b>{{label()}}</b></a>
      <ul>
        <li v-for="(item, key) in types" :key="key"><a href="#" :data-id="key" @click="choose">{{ item }}</a></li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "FilterSort",
  props: ['value'],
  data: function () {
    return {
      types: {
        1: 'Top Sellers',
        2: 'Newest',
        3: 'Popular'
      }
    };
  },
  methods: {
    label() {
      if (this.types[this.value] !== 'undefined') {
        return this.types[this.value];
      }

      return '';
    },
    choose(e) {
      var id = e.target.getAttribute('data-id');
      this.$emit('changed', id);
    }
  }
}
</script>

<style scoped>

</style>