<template>
  <footer>
    <div class="content-width">
      <div class="left">
        <div class="logo-footer">
          <router-link to="/">templeton</router-link>
        </div>
        <p>Ableton Live Templates are a quick and certain means to get straight to your desired genre sound. </p>
        <ul class="soc">
          <li><a href="#"><i class="fab fa-facebook-f"></i></a></li>
          <li><a href="#"><i class="fab fa-twitter"></i></a></li>
          <li><a href="#"><i class="fab fa-soundcloud"></i></a></li>
          <li><a href="#"><i class="fab fa-instagram"></i></a></li>
          <li><a href="#"><i class="fab fa-youtube"></i></a></li>
        </ul>
      </div>
      <div class="right">
        <nav class="footer-menu">
          <ul>
            <li><a href="#">Templates</a></li>
            <li><a href="#">Vocals</a></li>
            <li><a href="#">Drumkit</a></li>
            <li><a href="#">Presets</a></li>
            <li><a href="#">Contact Us</a></li>
          </ul>
        </nav>
        <div class="form-wrap">
          <h6>Subscribe to Newsletter</h6>
          <form action="#" class="footer-form">
            <label for="subscribe-email"></label>
            <input type="email" name="subscribe-email" id="subscribe-email" class="subscribe-email"
                   placeholder="Your email">
            <button type="submit" class="btn-default btn-blue">Send</button>
          </form>
          <p>By submitting my e-mail address, I consent to subscribe to this newsletter and have templeton.com
            contact me</p>
          <div class="pay-info">
            <ul>
              <li><img src="/img/icon-2-1.svg" alt=""></li>
              <li><img src="/img/icon-2-2.svg" alt=""></li>
              <li><img src="/img/icon-2-3.svg" alt=""></li>
              <li><img src="/img/icon-2-4.svg" alt=""></li>
            </ul>
          </div>
        </div>
      </div>
      <div class="bottom">
        <div class="left-wrap">
          <p>© 2021 Templeton. All rights reserved.</p>
        </div>
        <div class="right-wrap">
          <ul>
            <li><router-link to="/privacy-policy">Privacy Policy</router-link></li>
            <li><router-link to="/terms-and-conditions">Terms and Conditions</router-link></li>
            <li><router-link to="/about">About</router-link></li>
          </ul>
          <p>Designed by Webcreator</p>
        </div>
      </div>
    </div>
    <div class="footer-text" v-show="$store.state.footer_seo.length > 0">
      <div class="content-width" v-html="$store.state.footer_seo"></div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer"
}
</script>

<style scoped>

</style>